import React, { useEffect, useState } from "react";
import { StepperContainer, StepItem, Step, CompleteText } from "./StepperCss";
import { TiTick } from "react-icons/ti";
const Stepper = ({ currentStep }) => {
  const steps = ["Verifying Part", "Verifying Serial", "Merging data"];
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (currentStep === steps.length + 1) {
      setComplete(true);
    }
  }, []);

  return (
    <StepperContainer>
      {steps?.map((step, i) => (
        <StepItem
          key={i}
          className={`${currentStep === i + 1 ? "active" : ""} ${
            i + 1 < currentStep || complete ? "complete" : ""
          }`}
        >
          <Step
            className={`${currentStep === i + 1 ? "active" : ""} ${
              i + 1 < currentStep || complete ? "complete" : ""
            }`}
          >
            {i + 1 < currentStep || complete ? <TiTick size={24} /> : i + 1}
          </Step>
          <CompleteText
            className={`${i + 1 < currentStep || complete ? "complete" : ""}`}
          >
            {step}
          </CompleteText>
        </StepItem>
      ))}
    </StepperContainer>
  );
};

export default Stepper;
