import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import { item1 } from "./../../assets/data/wiki/data1";
import { item2 } from "./../../assets/data/wiki/data2";
import { item3 } from "../../assets/data/wiki/data3";
import { item4 } from "../../assets/data/wiki/data4";
import { item5 } from "../../assets/data/wiki/data5";
import { item6 } from "../../assets/data/wiki/data6";
import { item7 } from "../../assets/data/wiki/data7";
import { item12 } from "../../assets/data/wiki/data12";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./Wiki.css";
import BlockchainIcon from "./icons/BlockchainIcon";
import Moves from "./wikicomponents/Moves";
import Trainers from "./wikicomponents/Trainers";
import Chips from "./wikicomponents/Chips";
import Title from "./wikicomponents/Titles";
import Blockchain from "./wikicomponents/Blockchain";

const items = { item1, item2, item3, item7, item4, item12, item6, item5 };

const urlParamsMap = {
  history: "item1",
  assembly: "item2",
  "parts-system": "item3",
  store: "item7",
  modes: "item4",
  "xp-rank": "item5",
  strategy: "item6",
  moves: "item8",
  trainers: "item9",
  chips: "item10",
  ranks: "item11",
  blockchain: "item13",
};

const reverseUrlParamsMap = Object.fromEntries(
  Object.entries(urlParamsMap).map(([key, value]) => [value, key])
);

const trainers = [
  { name: "Andy" },
  { name: "Tai" },
  { name: "Max" },
  { name: "Aria" },
  { name: "Sami" },
  { name: "Dr. Yuto" },
  { name: "Dr. Yumi" },
  { name: "Yuri" },
  { name: "Nicola" },
];

const chips = [
  { name: "Insect" },
  { name: "Phoenix" },
  { name: "Tanuki" },
  { name: "Tiger" },
  { name: "Turtle" },
  { name: "Vesp" },
  { name: "Wolf" },
  { name: "Octopus" },
];

const moves = [{ name: "All Moves" }, { name: "Special Moves" }];

const ranks = [
  { title: "Bronze" },
  { title: "Silver" },
  { title: "Gold" },
  { title: "Platinum" },
  { title: "Diamond" },
  { title: "Pro Player" },
];

const Chains = [
  { title: "Moonbeam" },
  { title: "Immutable" },
  { title: "ICP" },
  { title: "Mint - NFT" },
  { title: "Withdraw - NFT" },
  { title: "Deposit - NFT" },
];

function Wiki() {
  const queryParams = new URLSearchParams(window.location.search);
  const initialSection =
    urlParamsMap[queryParams.get("section")] || Object.keys(items)[0];

  const [currentItem, setCurrentItem] = useState(initialSection);
  const [expandedItem, setExpandedItem] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSubItem, setCurrentSubItem] = useState(null);
  const [expandedTrainer, setExpandedTrainer] = useState(null);
  const [expandedChip, setExpandedChip] = useState(null);
  const [expandedMoves, setExpandedMoves] = useState(null);
  const [expandedTitle, setExpandedTitle] = useState(null);
  const [expendedChain, setExpendedChain] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest(".menu-overlay")) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleMenuClick = (item) => {
    setCurrentItem(item);
    setCurrentSubItem(null);
    const newUrl = `${window.location.pathname}?section=${reverseUrlParamsMap[item]}`;
    window.history.pushState(null, "", newUrl);

    setExpandedItem((prev) => (prev === item ? null : item));
    setExpandedTrainer(null);
  };

  const handleSubMenuClick = (subItem) => {
    setCurrentSubItem(subItem);
  };

  const handleMovesClick = (index) => {
    setExpandedMoves(expandedMoves === index ? null : index);
  };

  const handleTrainerClick = (index) => {
    setExpandedTrainer(expandedTrainer === index ? null : index);
  };

  const handleChipClick = (index) => {
    setExpandedChip(expandedChip === index ? null : index);
  };

  const handleTitleClick = (index) => {
    setExpandedTitle(expandedTitle === index ? null : index);
  };

  const handleBlockchainClick = (index) => {
    setExpendedChain(expendedChain === index ? null : index);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderMenuItems = () => (
    <>
      {Object.keys(items).map((key) => (
        <li key={key}>
          <h6
            style={{ borderRadius: "0px" }}
            href={`#${reverseUrlParamsMap[key]}`}
            onClick={() => handleMenuClick(key)}
            className={`item ${currentItem === key ? "active" : ""}`}
          >
            {items[key].title}
          </h6>
          {expandedItem === key && (
            <ul className="subtopic-list">
              {items[key].subTopics &&
                Object.keys(items[key].subTopics).map((subKey) => (
                  <li key={subKey} className="subtopic-item">
                    <Subtitle
                      onClick={() => handleSubMenuClick(subKey)}
                      className={`sub-item ${
                        currentSubItem === subKey ? "active" : ""
                      }`}
                    >
                      {items[key].subTopics[subKey].title}
                    </Subtitle>
                  </li>
                ))}
            </ul>
          )}
        </li>
      ))}
      <li>
        <h6
          href="#ranks"
          onClick={() => handleMenuClick("item11")}
          style={{ borderRadius: "0px" }}
          className={`item ${currentItem === "item11" ? "active" : ""}`}
        >
          🏆 Ranks
        </h6>
        {expandedItem === "item11" && (
          <ul className="subtopic-list">
            {ranks.map((title, index) => (
              <li key={index}>
                <Subtitle
                  onClick={() => handleTitleClick(index)}
                  className={`sub-item ${
                    expandedTitle === index ? "active" : ""
                  }`}
                >
                  {title.title}
                </Subtitle>
              </li>
            ))}
          </ul>
        )}
      </li>
      <li>
        <h6
          href="#moves"
          onClick={() => handleMenuClick("item8")}
          style={{ borderRadius: "0px" }}
          className={`item ${currentItem === "item8" ? "active" : ""}`}
        >
          ⚔️ Moves Library
        </h6>
        {expandedItem === "item8" && (
          <ul className="subtopic-list">
            {moves.map((move, index) => (
              <li key={index}>
                <Subtitle
                  onClick={() => handleMovesClick(index)}
                  className={`sub-item ${
                    expandedMoves === index ? "active" : ""
                  }`}
                >
                  {move.name}
                </Subtitle>
              </li>
            ))}
          </ul>
        )}
      </li>
      <li>
        <h6
          href="#trainers"
          onClick={() => handleMenuClick("item9")}
          style={{ borderRadius: "0px" }}
          className={`item ${currentItem === "item9" ? "active" : ""}`}
        >
          🧑 Trainers
        </h6>
        {expandedItem === "item9" && (
          <ul className="subtopic-list">
            {trainers.map((trainer, index) => (
              <li key={index}>
                <Subtitle
                  onClick={() => handleTrainerClick(index)}
                  className={`sub-item ${
                    expandedTrainer === index ? "active" : ""
                  }`}
                >
                  {trainer.name}
                </Subtitle>
              </li>
            ))}
          </ul>
        )}
      </li>
      <li>
        <h6
          href="#chips"
          onClick={() => handleMenuClick("item10")}
          style={{ borderRadius: "0px" }}
          className={`item ${currentItem === "item10" ? "active" : ""}`}
        >
          💾 Chips
        </h6>
        {expandedItem === "item10" && (
          <ul className="subtopic-list">
            {chips.map((chip, index) => (
              <li key={index}>
                <Subtitle
                  onClick={() => handleChipClick(index)}
                  className={`sub-item ${
                    expandedChip === index ? "active" : ""
                  }`}
                >
                  {chip.name}
                </Subtitle>
              </li>
            ))}
          </ul>
        )}
      </li>
      <li>
        <h6
          href="#blockchain"
          onClick={() => handleMenuClick("item13")}
          style={{
            borderRadius: "0px",
            marginTop: "-7px",
            display: "flex",
            alignItems: "center",
          }}
          className={`item ${currentItem === "item13" ? "active" : ""}`}
        >
          <BlockchainIcon size={30} />
          Blockchain
        </h6>
        {expandedItem === "item13" && (
          <ul className="subtopic-list">
            {Chains.map((chain, index) => (
              <li key={index}>
                <Subtitle
                  onClick={() => handleBlockchainClick(index)}
                  className={`sub-item ${
                    expendedChain === index ? "active" : ""
                  }`}
                >
                  {chain.title}
                </Subtitle>
              </li>
            ))}
          </ul>
        )}
      </li>
    </>
  );

  const Image = ({ node, ...props }) => (
    <img {...props} className="responsive-image" alt={props.alt} />
  );

  return (
    <Container>
      <HeaderTwo />
      <main>
        <section className="cta-bg-wiki">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="wiki-content">
              {isMenuOpen && (
                <MobileMenuOverlay isOpen={isMenuOpen} className="menu-overlay">
                  <CloseButton onClick={() => setIsMenuOpen(false)}>
                    X
                  </CloseButton>
                  <nav>
                    <ul>{renderMenuItems()}</ul>
                  </nav>
                </MobileMenuOverlay>
              )}
              {windowWidth >= 1200 && (
                <aside className="sidebarLeft">
                  <nav style={{ width: "100%" }}>
                    <ul>{renderMenuItems()}</ul>
                  </nav>
                </aside>
              )}
              <div className="content1">
                {windowWidth <= 1200 && (
                  <HamburgerMenuButton onClick={toggleMenu}>
                    <div />
                    <div />
                    <div />
                  </HamburgerMenuButton>
                )}
                {currentItem === "item8" ? (
                  <Moves selectedMoveType={expandedMoves} />
                ) : currentItem === "item9" ? (
                  <Trainers selectedTrainer={expandedTrainer} />
                ) : currentItem === "item10" ? (
                  <Chips selectedChip={expandedChip} />
                ) : currentItem === "item11" ? (
                  <Title selectedTitle={expandedTitle} />
                ) : currentItem === "item13" ? (
                  <Blockchain selectedChain={expendedChain} />
                ) : items[currentItem].subTopics && currentSubItem ? (
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={{ img: Image }}
                  >
                    {items[currentItem].subTopics[currentSubItem].text}
                  </Markdown>
                ) : (
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={{ img: Image }}
                  >
                    {items[currentItem].text}
                  </Markdown>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </Container>
  );
}

const Subtitle = styled.h6`
  cursor: pointer;
  color: #d3d2d2;
  font-size: 0.8rem;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const HamburgerMenuButton = styled.button`
  z-index: 1000;
  background: none;
  border: none;
  margin-left: -50px;
  margin-top: -20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 30px;
  transition: transform 0.3s ease;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  div {
    width: 30px;
    height: 3px;
    background: #d3d2d2;
    border-radius: 5px;
  }
`;

const MobileMenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: rgb(33, 23, 39, 0.9);
  color: white;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
  height: ${({ isOpen }) => (isOpen ? "450px" : "0")};
  transition: height 0.3s ease;
  border-radius: 0 0 10px 10px;

  nav ul {
    list-style: none;
    padding: 0;
  }

  nav ul li {
    margin-bottom: 15px;
  }

  .subtopic-list {
    margin-left: 20px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Wiki;
