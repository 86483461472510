import React from "react";

const CheckIcon = ({ size = 25, color = "#00bfff" }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      style={{ marginLeft: "10px" }}
    >
      <path
        d="M5.9 8.1 4.5 9.5 9 14 19 4l-1.4-1.4L9 11.2 5.9 8.1ZM18 10c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.8 0 1.5.1 2.2.3L13.8.7C12.6.3 11.3 0 10 0 4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10h-2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CheckIcon;
