import React from "react";

const InventoryIcon = ({ size }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 48 48"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="rgb(1, 31, 47)" fillOpacity="0.01" height={size} width={size} />
    <rect
      height="40"
      rx="2"
      stroke="rgb(1, 31, 47)"
      strokeLinejoin="round"
      strokeWidth="4"
      width="40"
      x="4"
      y="4"
    />
    <path
      d="M4 24H17"
      stroke="rgb(1, 31, 47)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
    />
    <path
      d="M31 24H44"
      stroke="rgb(1, 31, 47)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
    />
    <path
      d="M24 31C27.866 31 31 27.866 31 24C31 20.134 27.866 17 24 17C20.134 17 17 20.134 17 24C17 27.866 20.134 31 24 31Z"
      fill="#2F88FF"
      stroke="rgb(1, 31, 47)"
      strokeLinejoin="round"
      strokeWidth="4"
    />
  </svg>
);

export default InventoryIcon;
