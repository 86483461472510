import styled, { keyframes } from "styled-components";

export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 7rem;
`;

const fade = keyframes`
   0% {
    background-color: #0099ff; 
  }
  100% {
    background-color: #00bfff; 
  }
`;

export const StepItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 9rem;

  &:not(:first-child):before {
    content: "";
    background-color: #e2e8f0; /* Corresponds to bg-slate-200 */
    position: absolute;
    width: 100%;
    height: 3px;
    right: 50%;
    top: 33%;
    transform: translateY(-50%);
  }

  &.complete:not(:first-child):before,
  &.active:not(:first-child):before {
    background-color: #00bfff;
  }

  @media (max-width: 500px) {
    width: 6rem;
  }
`;

export const Step = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  background-color: rgb(148 163 184); /* Corresponds to bg-slate-700 */
  border-radius: 9999px;
  font-weight: 600;
  color: white;

  &.active {
    animation: ${fade} 0.8s infinite alternate;
    background-color: #00bfff; /* Corresponds to bg-sky-600 */
  }

  &.complete {
    background-color: #00bfff; /* Corresponds to bg-green-600 */
  }
`;

export const CompleteText = styled.p`
  color: #f0f0f0; /* Corresponds to text-gray-500 */
  &.complete {
    color: white;
  }

  @media (max-width: 500px) {
    font-size: 0.6rem;
  }
`;
