import React from "react";

const BlockchainIcon = ({ size = 40 }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      width: "25px",
      marginLeft: "0px",
      marginTop: "-2px",
    }}
  >
    <svg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="#c8ccf4"
    >
      <title>Ethereum Icon</title>
      <g id="Ethereum">
        <path d="M19.9988 0L19.5312 1.58506V26.624L19.9988 27.0915L32.5326 20.0136L19.9988 0Z" />
        <path d="M19.9988 0L7.46509 20.0136L19.9988 27.0915V14.4161V0Z" />
        <path d="M19.9988 29.1374L19.7246 29.4737V39.8187L19.9988 40.0001L32.5409 22.3669L19.9988 29.1374Z" />
        <path d="M19.9988 40.0001V29.1374L7.46509 22.3669L19.9988 40.0001Z" />
        <path d="M19.9988 27.0915L32.5326 20.0136L19.9988 14.4161V27.0915Z" />
        <path d="M7.46509 20.0136L19.9988 27.0915V14.4161L7.46509 20.0136Z" />
      </g>
    </svg>
  </div>
);

export default BlockchainIcon;
