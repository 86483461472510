import React, { useEffect, useState, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import "./Profile.css";
import { getAuth, signOut } from "firebase/auth";
import { FiLogOut } from "react-icons/fi";
import MintModal from "../../components/modals/mint/mintModal";
import DepositModal from "../../components/modals/deposit/DepositModal";
import InventoryIcon from "./icons/inventory";
import {
  avatarMapping,
  backgroundMapping,
  flagMapping,
  gearsMapping,
} from "./Mapping/Mapping";
import BackIcon from "./icons/back";
import WalletIcon from "./icons/wallet";
import ArrowDownIcon from "./icons/arrowdown";
import Inventory from "../../components/Inventory/Inventory";
import Wallets from "../../components/wallets/EVMWallets";
import Deposit from "../../components/modals/deposit/Deposit";

function Profile() {
  const [profileData, setProfileData] = useState(null);
  const [gears, setGears] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [showDeposit, setShowDeposit] = useState(false);

  const [gearsExibition, setGearsExibition] = useState(null);
  const [gearsData, setGearsData] = useState(null);
  const [popUpBg, setPopUpBg] = useState(null);
  const [showWallets, setShowWallets] = useState(false);
  // When walletConnected is one we att the data
  const [walletConnected, setWalletConnected] = useState(0);
  const [currentWallet, setCurrentWallet] = useState(null);

  const [removeNFT, setRemoveNFT] = useState(null);

  const openModal = (part, backgroundImage, partData) => {
    setGearsExibition(part);
    setPopUpBg(backgroundImage);
    setGearsData(partData);
    setIsModalOpen(true);
  };

  const openDepositModal = (
    robotPart,
    backgroundImage,
    item,
    walletAddressNFT
  ) => {
    setGearsExibition(robotPart);
    setPopUpBg(backgroundImage);
    setGearsData(item);
    setCurrentWallet(walletAddressNFT);
    setIsDepositModalOpen(true);
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {})
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  const fetchProfileData = useCallback(async () => {
    try {
      const jwt = localStorage.getItem("jwt");

      const [profileResponse, gearsResponse] = await Promise.all([
        fetch(
          "https://us-central1-polybots-d02df.cloudfunctions.net/checkUserAndGetInfo",
          {
            method: "GET",
            headers: { Authorization: `Bearer ${jwt}` },
          }
        ),
        fetch(
          "https://us-central1-polybots-d02df.cloudfunctions.net/getInventoryParts",
          {
            method: "GET",
            headers: { Authorization: `Bearer ${jwt}` },
          }
        ),
      ]);

      // Verifica se alguma das requisições falhou
      if (!profileResponse.ok || !gearsResponse.ok) {
        throw new Error("Error when fetching profile or inventory data");
      }

      // Parse das respostas JSON em paralelo
      const [profileData, gearsData] = await Promise.all([
        profileResponse.json(),
        gearsResponse.json(),
      ]);
      // Atualiza estados
      setProfileData(profileData);
      setGears(gearsData);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar os dados do perfil:", error);
      setLoading(false);
    }
  }, [setProfileData, setGears, setLoading]);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData, walletConnected]);

  function calculateLevel(totalXP) {
    const BaseXP = 100;
    const LevelMultiplier = 1.2;

    let level = 1;
    let currentThreshold = BaseXP;
    let previousThreshold = 0;

    while (totalXP >= currentThreshold) {
      level++;
      previousThreshold = currentThreshold;
      currentThreshold += Math.floor(
        BaseXP * Math.pow(LevelMultiplier, level - 1)
      );
    }

    const xpGained = totalXP - previousThreshold;
    const xpNeeded = currentThreshold - totalXP;

    return { level, xpGained, xpNeeded };
  }

  const handleInventory = () => {
    setShowInventory(!showInventory);
    setShowMenu(!showMenu);
  };

  const handleWallets = () => {
    setShowWallets(!showWallets);
    setShowMenu(!showMenu);
  };

  const handleUserWithoutWallet = () => {
    setShowInventory(false);
    setShowWallets(!showWallets);
  };

  // Need to set all to false when clicking back
  const handleBack = () => {
    setShowInventory(false);
    setShowWallets(false);
    setShowMenu(true);
  };

  const handleWithdraw = (gearPartId) => {
    setGears((prevGears) =>
      prevGears.filter((gear) => gear.partId !== gearPartId)
    );
  };

  const handleDeposit = () => {
    setShowDeposit(!showDeposit);
  };

  const handleWalledConnected = () => {
    setWalletConnected(walletConnected + 1);
  };

  const handleNFTDeposited = (gearPartId) => {
    setRemoveNFT(gearPartId);
  };

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="cta-bg">
          <div
            className="container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Container>
              {!profileData ? (
                <div className="loading-container-profile">
                  <div className="loading-wrapper-profile">
                    <h1 className="loading-title-profile">Loading...</h1>
                    <div className="loading-spinner-profile"></div>
                  </div>
                </div>
              ) : (
                <>
                  <BalanceHeader>
                    <div className="balance">
                      <img src="/assets/img/icon/gameIcons/Gem.png"></img>
                      <div className="background">{profileData.balanceGem}</div>
                    </div>
                    <div className="balance">
                      <img src="/assets/img/icon/gameIcons/dollar.png"></img>
                      <div className="background">
                        {profileData.balanceCoin}
                      </div>
                    </div>
                    <div className="balance">
                      <h4
                        style={{
                          zIndex: "2",
                          marginTop: "10px",
                          marginLeft: "15px",
                          marginRight: "-25px",
                          fontWeight: "normal",
                          fontSize: "1.2rem",
                        }}
                      >
                        FP:
                      </h4>
                      <div
                        className="background"
                        style={{ borderLeft: "2px solid #00bfff" }}
                      >
                        <span style={{ marginLeft: "20px" }}>
                          {profileData.wPoints}
                        </span>
                      </div>
                    </div>
                    {/* <InventoryButton
                      onClick={() => {
                        handleInventory();
                      }}
                    >
                      <InventoryIcon size={25} /> Inventory
                    </InventoryButton> */}
                    <LogoutButton
                      onClick={handleLogout}
                      style={{ marginLeft: "auto" }}
                    >
                      <FiLogOut size={20} style={{ marginRight: "5px" }} />
                      Logout
                    </LogoutButton>
                  </BalanceHeader>

                  <ProfileContainer>
                    <ProfileCard>
                      <AvatarBackgroundContainer>
                        <Background
                          src={backgroundMapping[profileData.avatarId]}
                          alt="Background"
                        />
                        <Avatar
                          src={avatarMapping[profileData.avatarId]}
                          alt="Avatar"
                        />
                      </AvatarBackgroundContainer>
                      <InfoContainer>
                        <TopInfo>
                          {profileData.nickname === "default" && (
                            <Username>Not Defined</Username>
                          )}
                          {profileData.nickname !== "default" && (
                            <Username>{profileData.nickname}</Username>
                          )}
                          <Flag
                            src={flagMapping[profileData.nationality]}
                            alt={`${profileData.nationality} Flag`}
                          />
                        </TopInfo>

                        {(() => {
                          const { level, xpGained, xpNeeded } = calculateLevel(
                            profileData.xp
                          );
                          return (
                            <>
                              <LevelContainer>
                                <Level>LVL: {level}</Level>
                              </LevelContainer>
                              <ProgressContainer>
                                <Progress>
                                  <ProgressBar
                                    progress={
                                      (xpGained / (xpGained + xpNeeded)) * 100
                                    }
                                  />
                                </Progress>
                              </ProgressContainer>
                            </>
                          );
                        })()}

                        <WinLossContainer>
                          <WinLoss>
                            W {profileData.win} / {profileData.lose} L
                          </WinLoss>
                        </WinLossContainer>
                      </InfoContainer>
                    </ProfileCard>

                    <MenuContainer>
                      {!showMenu && (
                        <MenuButtons>
                          <BackButton
                            onClick={() => {
                              handleBack();
                            }}
                          >
                            <BackIcon style={{ fill: "#00bfff" }} />{" "}
                            <span style={{ marginTop: "2px" }}>Back</span>
                          </BackButton>

                          <BackButton onClick={handleDeposit}>
                            <img
                              style={{ width: "25px", height: "25px" }}
                              src="/assets/img/icon/nft_hex.png"
                            />
                            <span style={{ marginTop: "2px" }}>Deposit</span>
                          </BackButton>
                        </MenuButtons>
                      )}

                      {showMenu && (
                        <Menu>
                          <div
                            onClick={() => {
                              handleInventory();
                            }}
                          >
                            <InventoryIcon size={30} />
                            Inventory
                          </div>
                          <div
                            onClick={() => {
                              handleWallets();
                            }}
                          >
                            <WalletIcon size={30} /> Wallets
                          </div>
                        </Menu>
                      )}

                      {showInventory && (
                        <Inventory gears={gears} openModal={openModal} />
                      )}

                      {showWallets && (
                        <Wallets
                          profileData={profileData}
                          bgimg={popUpBg}
                          gems={profileData.balanceGem}
                          walletConnected={handleWalledConnected}
                        />
                      )}

                      {showDeposit && (
                        <Deposit
                          profileData={profileData}
                          closeDeposit={handleDeposit}
                          gears={gears}
                          currentWallet={currentWallet}
                          openDepositModal={openDepositModal}
                          removeNFT={removeNFT}
                        />
                      )}
                    </MenuContainer>
                  </ProfileContainer>

                  {isDepositModalOpen && (
                    <DepositModal
                      gearsExibition={gearsExibition}
                      gearsData={gearsData}
                      bgimg={popUpBg}
                      closeModal={() => setIsDepositModalOpen(false)}
                      attGears={handleNFTDeposited}
                    />
                  )}

                  {isModalOpen && (
                    <MintModal
                      gearsExibition={gearsExibition}
                      gearsData={gearsData}
                      bgimg={popUpBg}
                      jwt={localStorage.getItem("jwt")}
                      gems={profileData.balanceGem}
                      gears={gears}
                      farmPoints={profileData.wPoints}
                      profileData={profileData}
                      attGears={(e) => handleWithdraw(e)}
                      connectWallet={handleUserWithoutWallet}
                      closeModal={() => setIsModalOpen(false)}
                    />
                  )}
                </>
              )}
            </Container>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

const Container = styled.div`
  background-color: #1c1424;
  border-radius: 20px;
  width: 80%;
  height: 600px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  background-image: url("assets/img/bg/garage.jpeg");
  background-size: cover;
  background-position: center;
  position: relative; /* Adicionado */

  @media (max-width: 450px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    margin-top: 10px;
    height: 650px;
  }
`;

const ProfileContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const BalanceHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 75px;

  img {
    width: 30px;
    height: 30px;
    z-index: 2;
  }

  .balance {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100px;
    margin-left: 10px;
  }

  .background {
    background-color: rgba(0, 51, 102, 0.6);
    margin-left: -15px;
    width: 100%;
    height: 100%;
    border-top: 2px solid #00bfff;
    border-right: 2px solid #00bfff;
    border-bottom: 2px solid #00bfff;
    border-radius: 5px;
    z-index: 1;
    color: white;
  }

  @media (max-width: 992px) {
    margin-bottom: 10px;
  }

  @media (max-width: 770px) {
    justify-content: space-evenly;
    .balance {
      margin-left: 0px;
    }
  }

  @media (max-width: 375px) {
    .balance {
      width: 85px;
    }
  }
`;

const InventoryButton = styled.button`
  display: flex;
  justify-content: center;
  aligncontent: center;
  gap: 5px;
  color: white;
  background: #1c1424;
  padding: 8px 16px;
  margin-left: auto;
  border-radius: 5px;
  cursor: pointer;
  border: none;

  @media (max-width: 992px) {
    margin-top: 5px;
    margin-right: 10px;
  }

  @media (max-width: 770px) {
    margin-inline: auto;
  }
`;

const LogoutButton = styled.button`
  margin: 0px 20px 0px 20px;
  background-color: #ff6347;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background-color: #e5533d;
  }

  @media (max-width: 500px) {
    padding: 8px;
    &::before {
      font-size: 16px;
    }
    &::after {
      content: none;
    }
  }

  @media (max-width: 770px) {
    margin-inline: auto;
  }
`;

const ProfileCard = styled.div`
  display: flex;
  background-color: #273444;
  border-radius: 10px;
  padding: 15px;
  width: 350px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  transition: transform 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 500px) {
    width: 325px;
  }

  @media (max-width: 450px) {
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 90%;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const AvatarBackgroundContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;

  @media (max-width: 450px) {
    margin-inline: auto;
    width: 90px;
    height: 90px;
  }
`;

export const growAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const MenuContainer = styled.div`
  width: 75%;
  height: 60%;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

const MenuButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BackButton = styled.button`
  height: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: white;
  background: #273444;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  margin-bottom: 10px;

  @media (max-width: 992px) {
    margin-top: 5px;
  }

  @media (max-width: 450px) {
    margin-top: 0px;
  }
`;

const Menu = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 15px;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(1, 31, 47);
  border-radius: 10px;
  border: 3px solid #00bfff;
  animation: ${growAnimation} 0.1s ease-in-out;

  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80px;
    height: 80px;
    gap: 5px;
    background-color: rgb(255, 250, 250);
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.2s;
    font-size: 0.8rem;
    color: rgb(1, 31, 47);
  }

  div:hover {
    transform: scale(1.03); /* Efeito de scale no hover */
  }

  .icon {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 770px) {
    div {
      width: 80px;
      height: 80px;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }

  @media (max-width: 550px) {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);

    div {
      width: 120px;
      height: 120px;
      margin-inline: auto;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 450px) {
    padding: 10px;

    div {
      width: 110px;
      height: 110px;
      margin-inline: auto;
    }

    img {
      width: 90px;
      height: 90px;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #273444;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bfff;
    border-radius: 10px;
    border: 2px solid #273444;
  }

  scrollbar-width: thin;
  scrollbar-color: #3498db #273444;

  .background {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transition: 0.2s;
      transform: scale(1.05);
    }
  }
`;

const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TopInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const LevelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ProgressContainer = styled.div`
  margin-top: 5px;
`;

const WinLossContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Avatar = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 1;
`;

const Username = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
`;

const Flag = styled.img`
  width: 30px;
  height: 30px;
`;

const Level = styled.div`
  color: #00bfff;
  font-size: 16px;
`;

const Progress = styled.div`
  background-color: #444b5b;
  border-radius: 5px;
  width: 100%;
`;

const ProgressBar = styled.div`
  background-color: #00bfff;
  border-radius: 5px;
  width: ${({ progress }) => progress}%;
  height: 8px;
`;

const WinLoss = styled.div`
  background-color: #273444;
  border-radius: 5px;
  padding: 4px 8px;
  color: #00ff00;
  font-weight: bold;
  font-size: 14px;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #273444;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  text-align: center;
  color: #fff;
  margin: 18px;
  overflow-y: auto; /* Adicione esta linha */
`;

const TermsText = styled.div`
  height: 243px;
  overflow-y: auto;
  text-align: justify;
  margin-bottom: 20px;
  padding: 20px;
  scroll-behavior: smooth;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #273444;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bfff;
    border-radius: 10px;
    border: 2px solid #273444;
  }

  scrollbar-width: thin;
  scrollbar-color: #00bfff #273444;

  @media (max-width: 500px) {
    font-size: 13px;
    height: 280px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #00bfff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;

  &:hover {
    background-color: #0099cc;
  }
`;

const LinkA = styled.a`
  color: #00bfff;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #0099cc;
  }
`;

export default Profile;
