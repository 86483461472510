import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Omoonbeam() {

  const HoverImage = styled.img`
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 90%;
    cursor: pointer;
    max-width: 100px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }

        @media (max-width: 768px) {
      margin-top: 50px;
    }
  `;

  const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    gap: 20%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  `;

  return (
    <section className="cta-area cta-bg2" style={{backgroundColor:'white'}}>

<div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          marginBottom:'50px'
        }}
      >
        <h2 style={{color:'black', fontSize:'40px'}}>
          POWERED <span style={{color: "#40a5f8" }}>BY</span>
        </h2>
        <Container
        >
          <a
            href="https://moonbeam.network/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HoverImage
              style={{ maxWidth: "400px", width: "100%" }}
              src="assets/img/partners/moonbeamb.png"
              alt="aaa"
              className="hover-effect"
            />
          </a>
          <a
            href="https://internetcomputer.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HoverImage
              style={{ maxWidth: "400px", width: "100%" }}
              src="assets/img/partners/ICP.png"
              alt="aaa"
              className="hover-effect"
            />
          </a>
        </Container>
      </div>
    </section>
  );
}

export default Omoonbeam;
