import React, { useEffect, useState } from "react";
import {
  growAnimation,
  Popup,
  CloseButton,
  PopupContent,
  AttributesContainer,
  Overlay,
  CardBackground,
  CardContainer,
  CardGlow,
  CardContent,
  CardWrapper,
} from "../mint/mintModalcss";
import { useActiveWallet } from "thirdweb/react";
import { transferFrom } from "thirdweb/extensions/erc721";
import { getContract, sendTransaction } from "thirdweb";
import { createWallet } from "thirdweb/wallets";
import { myChain } from "../../../index";
import { client } from "../../../context/client";

export default function DepositModal({
  gearsExibition,
  gearsData,
  bgimg,
  walletAddressNFT,
  closeModal,
  attGears,
}) {
  const wallet = useActiveWallet();

  const contract = getContract({
    address: "0xE5bc29Bc98faAAE718F200126Dfd341702163477",
    chain: myChain,
    client: client,
  });

  const walletOptions = {
    wallets: [
      "io.metamask",
      "com.coinbase.wallet",
      "com.trustwallet.app",
      "app.phantom",
      "me.rainbow",
      "io.zerion.wallet",
    ],
  };

  const handleTransactionSetup = async () => {
    console.log("clickado");
    try {
      if (!wallet) {
        throw new Error("No wallet connected");
      }

      // Verifica se a carteira selecionada está disponível nas opções
      if (!walletOptions.wallets.includes(wallet.id)) {
        throw new Error("Wallet not supported");
      }

      // Cria a instância da carteira escolhida
      const thirdWallet = createWallet(wallet.id);

      const account = await thirdWallet.connect({
        client,
      });

      const transaction = transferFrom({
        contract: contract,
        from: walletAddressNFT,
        to: "0xd53Ad66B2741C5B683485D1613871818d5dEf1F4",
        tokenId: gearsData.tokenId,
        overrides: {},
      });

      console.log(transaction);

      const result = await sendTransaction({
        account,
        transaction,
      });

      attGears(gearsData.tokenId);
      return {
        success: true,
        message: "Transaction successful",
        data: {
          transactionHash: result.transactionHash,
        },
      };
    } catch (error) {
      return {
        success: false,
        message: "Transaction setup failed",
        error: error.message,
      };
    }
  };

  const isChip = gearsExibition.name.includes("Chip");

  return (
    <>
      <Overlay style={{ zIndex: "905" }} onClick={() => closeModal(true)} />
      <Popup style={{ zIndex: "906" }} className={isChip ? "shortSize" : ""}>
        <CloseButton onClick={() => closeModal(true)}>×</CloseButton>
        <PopupContent>
          <div>
            <div
              style={{
                height: "7rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#00bfff",
              }}
            >
              <h2 style={{ fontSize: "30px", color: "white" }}>
                {gearsExibition.name}
              </h2>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `${bgimg}`,
              backgroundSize: "cover",
              width: "180px",
              height: "180px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-1.5rem",
            }}
          >
            <img width="150" height="150" src={gearsExibition.image}></img>
          </div>
          {!isChip && (
            <AttributesContainer>
              <div>
                <h6>Attack:</h6>
                <h6 className="blue">{gearsData.attack}</h6>
              </div>
              <div>
                <h6>Defense:</h6>
                <h6 className="blue">{gearsData.defense}</h6>
              </div>
              <div>
                <h6>Health:</h6>
                <h6 className="blue">{gearsData.health}</h6>
              </div>
              <div>
                <h6>Agility:</h6>
                <h6 className="blue">{gearsData.agility}</h6>
              </div>
              <div>
                <h6>Energy:</h6>
                <h6 className="blue">{gearsData.energy}</h6>
              </div>
            </AttributesContainer>
          )}
          <CardWrapper>
            <CardContainer
              onClick={(event) => handleTransactionSetup(event)}
              style={{ cursor: "pointer" }}
            >
              <CardContent>Deposit</CardContent>
              <CardBackground />
              <CardGlow />
            </CardContainer>
          </CardWrapper>
        </PopupContent>
      </Popup>
    </>
  );
}
