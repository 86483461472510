import React, { useState, useEffect } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import Trophy from "../../assets/img/icon/Trophy";
import "./RankingPlayers.css";
import ConfettiComponent from "../../components/confetti/Confetti";
import styled from "styled-components";
import { error } from "jquery";

function RankingPlayers() {
  const titles = [
    {
      title: "Bronze",
      tiers: [
        {
          tier: "I",
          level: 1,
          points: "0",
          image: "/assets/img/wiki/tiers-rank/Bronze_1.png",
        },
        {
          tier: "II",
          level: 2,
          points: "100",
          image: "/assets/img/wiki/tiers-rank/Bronze_2.png",
        },
        {
          tier: "III",
          level: 3,
          points: "200",
          image: "/assets/img/wiki/tiers-rank/Bronze_3.png",
        },
        {
          tier: "IV",
          level: 4,
          points: "350",
          image: "/assets/img/wiki/tiers-rank/Bronze_4.png",
        },
        {
          tier: "V",
          level: 5,
          points: "550",
          image: "/assets/img/wiki/tiers-rank/Bronze_5.png",
        },
      ],
    },
    {
      title: "Prata",
      tiers: [
        {
          tier: "I",
          level: 6,
          points: "800",
          image: "/assets/img/wiki/tiers-rank/Silver_1.png",
        },
        {
          tier: "II",
          level: 7,
          points: "1100",
          image: "/assets/img/wiki/tiers-rank/Silver_2.png",
        },
        {
          tier: "III",
          level: 8,
          points: "1500",
          image: "/assets/img/wiki/tiers-rank/Silver_3.png",
        },
        {
          tier: "IV",
          level: 9,
          points: "2000",
          image: "/assets/img/wiki/tiers-rank/Silver_4.png",
        },
        {
          tier: "V",
          level: 10,
          points: "2600",
          image: "/assets/img/wiki/tiers-rank/Silver_5.png",
        },
      ],
    },
    {
      title: "Ouro",
      tiers: [
        {
          tier: "I",
          level: 11,
          points: "3300",
          image: "/assets/img/wiki/tiers-rank/Gold_1.png",
        },
        {
          tier: "II",
          level: 12,
          points: "4100",
          image: "/assets/img/wiki/tiers-rank/Gold_2.png",
        },
        {
          tier: "III",
          level: 13,
          points: "5000",
          image: "/assets/img/wiki/tiers-rank/Gold_3.png",
        },
        {
          tier: "IV",
          level: 14,
          points: "6000",
          image: "/assets/img/wiki/tiers-rank/Gold_4.png",
        },
        {
          tier: "V",
          level: 15,
          points: "7100",
          image: "/assets/img/wiki/tiers-rank/Gold_5.png",
        },
      ],
    },
    {
      title: "Platina",
      tiers: [
        {
          tier: "I",
          level: 16,
          points: "8300",
          image: "/assets/img/wiki/tiers-rank/Platinum_1.png",
        },
        {
          tier: "II",
          level: 17,
          points: "9600",
          image: "/assets/img/wiki/tiers-rank/Platinum_2.png",
        },
        {
          tier: "III",
          level: 18,
          points: "11000",
          image: "/assets/img/wiki/tiers-rank/Platinum_3.png",
        },
        {
          tier: "IV",
          level: 19,
          points: "13000",
          image: "/assets/img/wiki/tiers-rank/Platinum_4.png",
        },
        {
          tier: "V",
          level: 20,
          points: "15000",
          image: "/assets/img/wiki/tiers-rank/Platinum_5.png",
        },
      ],
    },
    {
      title: "Diamante",
      tiers: [
        {
          tier: "I",
          level: 21,
          points: "17000",
          image: "/assets/img/wiki/tiers-rank/Diamond_1.png",
        },
        {
          tier: "II",
          level: 22,
          points: "18500",
          image: "/assets/img/wiki/tiers-rank/Diamond_2.png",
        },
        {
          tier: "III",
          level: 23,
          points: "20000",
          image: "/assets/img/wiki/tiers-rank/Diamond_3.png",
        },
        {
          tier: "IV",
          level: 24,
          points: "21500",
          image: "/assets/img/wiki/tiers-rank/Diamond_4.png",
        },
        {
          tier: "V",
          level: 25,
          points: "23000",
          image: "/assets/img/wiki/tiers-rank/Diamond_5.png",
        },
      ],
    },
    {
      title: "Pro",
      tiers: [
        {
          tier: "I",
          level: 26,
          title: "Regional ",
          image: "/assets/img/wiki/tiers-rank/Pro_1.png",
        },
        {
          tier: "II",
          level: 27,
          title: "Mundial",
          image: "/assets/img/wiki/tiers-rank/Pro_2.png",
        },
        {
          tier: "III",
          level: 28,
          title: "Elite",
          image: "/assets/img/wiki/tiers-rank/Pro_3.png",
        },
      ],
    },
  ];

  const [rankingData, setRankingData] = useState([]);
  const [topPointesUsers, setTopPointesUsers] = useState([]);
  const [topWpointsUsers, setTopWpointsUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [isRankPoints, setIsRankPoints] = useState(true);

  const toggleRank = () => {
    setIsRankPoints(!isRankPoints);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://us-central1-polybots-d02df.cloudfunctions.net/getUsersRank"
        );
        const data = await response.json();

        if (data.topPointsUsers && Array.isArray(data.topPointsUsers)) {
          const sortedPointsData = data.topPointsUsers.sort(
            (a, b) => b.points - a.points
          );
          setTopPointesUsers(sortedPointsData);
        } else {
          console.log("Erro: Dados de topPointsUsers não encontrados.");
        }

        if (data.topWpointsUsers && Array.isArray(data.topWpointsUsers)) {
          const sortedWpointsData = data.topWpointsUsers.sort(
            (a, b) => b.wPoints - a.wPoints
          );
          setTopWpointsUsers(sortedWpointsData);
        } else {
          console.log("Erro: Dados de topWpointsUsers não encontrados.");
        }
      } catch (error) {
        console.error("Erro ao buscar dados de ranking:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCommunityClick = (index) => {
    setSelectedCommunity(selectedCommunity === index ? null : index);
  };

  const getShieldImage = (points) => {
    for (let i = titles.length - 1; i >= 0; i--) {
      const rank = titles[i];
      for (let j = rank.tiers.length - 1; j >= 0; j--) {
        const tier = rank.tiers[j];
        if (tier.points && points >= parseInt(tier.points, 10)) {
          return tier.image;
        }
      }
    }
    return titles[0].tiers[0].image;
  };

  const getTierInfo = (points) => {
    for (let i = titles.length - 1; i >= 0; i--) {
      const rank = titles[i];
      for (let j = rank.tiers.length - 1; j >= 0; j--) {
        const tier = rank.tiers[j];
        if (tier.points && points >= parseInt(tier.points, 10)) {
          return {
            image: tier.image,
            name: `${rank.title} ${tier.tier}`,
          };
        }
      }
    }
    return {
      image: titles[0].tiers[0].image,
      name: `${titles[0].title} ${titles[0].tiers[0].tier}`,
    };
  };

  const displayedUsers = isRankPoints ? topPointesUsers : topWpointsUsers;

  const getTrophy = (index) => {
    if (index === 0) return "#FFD700"; // Ouro
    if (index === 1) return "#C0C0C0"; // Prata
    if (index === 2) return "#CD7F32"; // Bronze
    return "transparent"; // Outros
  };

  const getIcon = (points, index) => {
    if (!isRankPoints) {
      if (index < 3) {
        return (
          <Trophy
            className="trophy-icon"
            style={{
              width: isSmallScreen ? "16px" : "24px",
              height: isSmallScreen ? "16px" : "24px",
              fill: getTrophy(index),
            }}
          />
        );
      } else {
        return null;
      }
    } else {
      return (
        <img
          src={getShieldImage(points)}
          alt={`Shield for ${points} points`}
          style={{ width: "30px", height: "30px" }}
        />
      );
    }
  };

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="rank-bg">
          <div className="container">
            <div className="ranking-players-content">
              <h1 style={{ marginTop: "50px" }}>
                {isRankPoints ? "Top Rank Points" : "Top Farm Points"}
              </h1>
              <ButtonContainer style={{marginBottom:'-20px'}}>
              <div className="button-group">
                <button
                  className={`rank-btn ${isRankPoints ? "active" : ""}`}
                  onClick={() => setIsRankPoints(true)} 
                >
                  Rank Points
                </button>
                <button
                  className={`rank-btn ${!isRankPoints ? "active" : ""}`}
                  onClick={() => setIsRankPoints(false)}
                >
                  Farm Points
                </button>
              </div>
            </ButtonContainer>
              <div className="ranking-players-table">
                <ul className="ranking-players-list">
                  <li className="ranking-players-header ranking-players-row-header">
                    <div>
                      <h4>Rank</h4>
                    </div>
                    <div>
                      <h4>Player</h4>
                    </div>
                    <div>
                      <h4>{isRankPoints ? "Points" : "Points"}</h4>
                    </div>
                  </li>
                  {isLoading ? (
                    <div className="loading-container">
                      <div className="loading-wrapper">
                        <h1 className="loading-title">Loading...</h1>
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                  ) : (
                    displayedUsers.map((player, index) => (
                      <React.Fragment key={index}>
                        <li
                          className="ranking-players-row"
                          onClick={() => handleCommunityClick(index)}
                          style={{
                            boxShadow:
                              index === 0 ? "0px 0px 15px #89CFF0" : "none",
                            transform: index === 0 ? "scale(1.03)" : "",
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            cursor: isRankPoints ? "pointer" : "default",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {getIcon(
                              isRankPoints ? player.points : player.wPoints,
                              index
                            )}
                            <h6
                              style={{ marginLeft: "5px", marginRight: "10px" }}
                            >
                              {index + 1}
                            </h6>
                          </div>
                          <div>
                            <h6>{player.nickname}</h6>
                          </div>
                          <div>
                            <h6>
                              {isRankPoints ? player.points : player.wPoints}
                            </h6>
                          </div>
                        </li>
                        {selectedCommunity === index && isRankPoints && (
                          <DetailView>
                            <RankInfo>
                              <h6>
                                {
                                  getTierInfo(
                                    isRankPoints
                                      ? player.points
                                      : player.wPoints
                                  ).name
                                }
                              </h6>
                              <img
                                src={
                                  getTierInfo(
                                    isRankPoints
                                      ? player.points
                                      : player.wPoints
                                  ).image
                                }
                                alt={`${
                                  getTierInfo(
                                    isRankPoints
                                      ? player.points
                                      : player.wPoints
                                  ).name
                                } shield`}
                                style={{ width: "200px", height: "200px" }}
                              />
                            </RankInfo>
                          </DetailView>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const DualButton = styled.button`
  position: relative;
  width: 100px;
  height: 35px;
  border: none;
  border-radius: 25px;
  background-color: #4e3a4c;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  font-family: Arial, sans-serif;

  span {
    position: absolute;
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    z-index: 2;
    transition: color 0.3s ease;
  }

  .rank {
    left: 0;
  }

  .farm {
    right: 0;
  }
`;

const Slider = styled.div`
  position: absolute;
  top: 0;
  left: ${({ active }) => (active ? "0" : "50%")};
  width: 50%;
  height: 100%;
  background-color: rgba(64, 165, 248, 1);
  border-radius: 25px;
  transition: left 0.3s ease;
  z-index: 1;
`;

const DetailView = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RankInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    margin: 0 0 10px 0;
    font-size: 18px;
    color: #ffffff;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 16px;
    }

    img {
      width: 150px;
      height: 150px;
    }
  }
`;

export default RankingPlayers;
