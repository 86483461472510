import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import HomeTwo from "./pages/homes/HomeTwo";
import HomeOne from "./pages/homes/HomeOne";
import HomeThree from "./pages/homes/HomeThree";
import Shop from "./pages/store/Shop";
import Contact from "./pages/contact/Contact";
import Community from "./pages/community/Community";
import Overview from "./pages/overview/Overview";
import AboutUs from "./pages/aboutpage/aboutuspage/AboutUs";
import Upcoming from "./pages/aboutpage/upcoming/Upcoming";
import GameSingle from "./pages/aboutpage/singlegame/GameSingle";
import Blogs from "./pages/blogs/Blogs";
import BlogDetails from "./pages/blogdetails/BlogDetails";
import ScrollToTopRoute from "./components/scrolltotop/ScrollToTopRoute";
import HomeFour from "./pages/homes/HomeFour";
import HomeFive from "./pages/homes/HomeFive";
import HomeSix from "./pages/homes/HomeSix";
import HomeSeven from "./pages/homes/HomeSeven";
import Login from "./pages/authpages/login/Login";
import Wiki from "./pages/wiki/Wiki";
import Ranking from "./pages/ranking/Ranking";
import Refers from "./pages/refers/Refers";
import Investors from "./pages/authpages/investors/Investors";
import TermsConditionals from "./pages/terms&conditionals/Terms&Conditionals";
import PrivacyPolice from "./pages/privacypolice/PrivacyPolice";
import TagManager from "react-gtm-module";
import DownloadPage from "./pages/downloadpage/Download";
import LoginPage from "./pages/authpages/loginPage/LogingPage";
import Profile from "./pages/profile/Profile";
import { AuthProvider } from "./hooks/useAuth";
import ProtectedRoute from "./components/protectrouter/ProtectedRoute";
import Polybots from "./pages/polybots/polybots";
import RankingPlayers from "./pages/rankingPlayers/RankingPlayers";
import RankingMoonbeam from "./pages/rankingMoonbeam/RankingMoonbeam";

function App() {
  useEffect(() => {
    // Add GTM script to head
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WP3ZQSF8');
    `;
    document.head.appendChild(script);

    // Add noscript element to body
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WP3ZQSF8"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noscript);
  }, []);

  const tagManagerArgs = {
    gtmId: "GTM-WP3ZQSF8",
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Switch>
            <Router>
              <Switch>
                <ScrollToTopRoute exact={true} path="/">
                  <HomeTwo />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/alpha">
                  <Login />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/polybots">
                  <Polybots />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/wiki">
                  <Wiki />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/ranking">
                  <RankingPlayers />
                </ScrollToTopRoute>
                {/* <ScrollToTopRoute exact={true} path="/ranking-players">
                  <RankingPlayers />
                </ScrollToTopRoute> */}
                <ScrollToTopRoute exact={true} path="/ranking-moonbeam">
                  <RankingMoonbeam />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/refers">
                  <Refers />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/investors">
                  <Investors />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/terms">
                  <TermsConditionals />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/privacy">
                  <PrivacyPolice />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/download">
                  <DownloadPage />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/login">
                  <LoginPage />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/login">
                  <LoginPage />
                </ScrollToTopRoute>
                <ProtectedRoute
                  exact={true}
                  path="/profile"
                  component={Profile}
                />
              </Switch>
            </Router>
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;

{
  /* <ScrollToTopRoute exact={true} path='/index-2'>
            <HomeOne />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-3'>
            <HomeThree />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/shop'>
            <Shop />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/contact'>
            <Contact />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/community'>
            <Community />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/overview'>
            <Overview />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/about-us'>
            <AboutUs />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/upcoming-games'>
            <Upcoming />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/game-single'>
            <GameSingle />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/blogs'>
            <Blogs />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/blog-details'>
            <BlogDetails />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-4'>
            <HomeFour />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-5'>
            <HomeFive />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-6'>
            <HomeSix />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path='/index-7'>
            <HomeSeven />
          </ScrollToTopRoute> */
}
