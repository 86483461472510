import {
  ConnectButton,
  useActiveWallet,
  useSwitchActiveWalletChain,
} from "thirdweb/react";
import { createAuth } from "thirdweb/auth";
import { createWallet, walletConnect } from "thirdweb/wallets";
import { client } from "../../context/client";
import { useEffect, useState } from "react";

const wallets = [
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("com.trustwallet.app"),
  createWallet("io.zerion.wallet"),
  createWallet("me.rainbow"),
  createWallet("app.phantom"),
  walletConnect(),
];

export default function WalletConnect({
  user, // User data is needed
  theme, // Dark and Light, color theme
  walletConnected, // Callbackfunction to reload ProfileData when user connect his wallet, only works if linkWalletToUser is true
  linkWalletToUser = false, // True or False, if need to call function linkWalletToUser when a wallet is detected
  showWalledText, // True or False, This text is to show user that linkWalletToUser is being called
  actualWallet, // Always return the actualWallet
}) {
  const wallet = useActiveWallet();
  const [address, setAddress] = useState(null);
  const jwt = localStorage.getItem("jwt");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const thirdwebAuth = createAuth({
    domain: "localhost:3000",
    client,
  });

  useEffect(() => {
    if (!user.wallet === "none") {
      setLoggedIn(true);
    }
  }, [user]);

  useEffect(() => {
    async function fetchAddress() {
      if (wallet) {
        setLoading(true);
        try {
          const address = wallet.getAccount();
          setAddress(address);

          if (address.address !== user.wallet.address) {
            const response = await fetch(
              "https://us-central1-polybots-d02df.cloudfunctions.net/linkWalletToUser",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${jwt}`,
                },
                body: JSON.stringify({
                  wallet: address,
                }),
              }
            );
            const data = await response.json();

            if (!response.ok) {
              setLoading(false);
              throw new Error(`Erro na requisição: ${response.statusText}`);
            } else if (data.message === "Wallet linked successfully") {
              setLoading(false);
              walletConnected(true);
            }
            // setContratulations(true);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error in connect wallet or make the request:", error);
        }
      }
    }
    if (linkWalletToUser) {
      fetchAddress();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (wallet && typeof actualWallet === "function") {
      const address = wallet.getAccount();
      actualWallet(address);
    } else if (typeof actualWallet === "function") {
      actualWallet(null);
    }
  }, [wallet, actualWallet]);

  return (
    <>
      {/* {congratulations && <ConfettiComponent active={true} repeat={1} />} */}
      <ConnectButton
        client={client}
        wallets={wallets}
        theme={theme}
        connectModal={{ size: "compact" }}
        // connectButton={{ label: "Connect Wallet to Join LGF" }}
        connectButton={{ label: "Connect Wallet" }}
        auth={{
          getLoginPayload: async (params) => {
            // here you should call your backend, using generatePayload to return
            // a SIWE compliant login payload to the client
            return thirdwebAuth.generatePayload(params);
          },
          doLogin: async (params) => {
            // here you should call your backend to verify the signed payload passed in params
            // this will verify that the signature matches the intended wallet
            const verifiedPayload = await thirdwebAuth.verifyPayload(params);
            if (verifiedPayload.valid) {
              setLoggedIn(true);
              localStorage.setItem("auth", JSON.stringify(verifiedPayload));
            }
            // register user
          },
          isLoggedIn: async () => {
            // here you should ask you backend if the user is logged in
            // can use cookies, storage, or your method of choice
            const authData = localStorage.getItem("auth");
            return authData ? JSON.parse(authData).valid : false;
          },
          doLogout: async () => {
            // here you should call your backend to logout the user if needed
            // and delete any local auth tokens
            setLoggedIn(false);
          },
        }}
      />
      {showWalledText &&
        (wallet ? (
          <h5 style={{ marginTop: "20px" }}>
            {loading ? (
              <>
                <span style={{ color: "#00bfff" }}>Linking</span> Wallet
              </>
            ) : (
              <>
                <span style={{ color: "#00bfff" }}>Wallet linked</span>{" "}
                successfully
              </>
            )}
          </h5>
        ) : (
          ""
        ))}
    </>
  );
}
