import React, { useEffect, useRef, useState } from "react";
import { polybots } from "./../../../assets/data/polybots/polybots";
import ArrowIcon from "./icons/arrow";
import StarIcon from "./icons/star";
import ThreeStarsIcon from "./icons/threestarts";
import {
  Popup,
  CloseButton,
  PopupContent,
  PopupHeader,
  PolybotRecord,
  RecordData,
  ContainerStatusData,
  StatusData,
  Overlay,
  Robot,
  RobotChip,
  GearStats,
  RobotSkills,
  RobotBoxes,
  StarDiv,
  StarrrDiv,
  GearBackground,
  ImageIcon,
} from "./polybotsCss";

export default function PolybotsModal({ closeModal, robotName }) {
  const BoxBlue = "assets/img/polybots/chests/BasicChest.png";
  const BoxGreen = "assets/img/polybots/chests/ProChest.png";
  const BoxRed = "assets/img/polybots/chests/AdvancedChest.png";
  const BoxMoonbeam = "assets/img/polybots/chests/MoonbeamChest.png";
  const BoxICP = "assets/img/polybots/chests/IcpChest.png";

  const [robotData, setRobotData] = useState(null);
  const GearRef = useRef(null);
  const LegsRef = useRef(null);

  const polybotsData = polybots;

  const boxesConfig = {
    default: [
      {
        name: "Basic Chest",
        img: BoxBlue,
        price: 98,
        starChances: { oneStar: "70%", twoStar: "30%", threeStar: "0%" },
      },
      {
        name: "Pro Chest",
        img: BoxGreen,
        price: 198,
        starChances: { oneStar: "40%", twoStar: "55%", threeStar: "5%" },
      },
      {
        name: "Advanced Chest",
        img: BoxRed,
        price: 490,
        starChances: { oneStar: "15%", twoStar: "70%", threeStar: "15%" },
      },
    ],
    "WLF-720": [
      {
        name: "WLF-720 Chest",
        img: BoxMoonbeam,
        price: 490,
        starChances: { oneStar: "15%", twoStar: "70%", threeStar: "15%" },
      },
    ],
    "OCT-950": [
      {
        name: "OCT-950 Chest",
        img: BoxICP,
        price: 490,
        starChances: { oneStar: "15%", twoStar: "70%", threeStar: "15%" },
      },
    ],
  };

  const boxes = boxesConfig[robotName] || boxesConfig.default;

  useEffect(() => {
    setRobotData(polybotsData[robotName]);
  }, [robotName]);

  if (!robotData) {
    return <div>Loading...</div>;
  }

  const scrollToGear = () => {
    GearRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToLegs = () => {
    LegsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const close = () => {
    closeModal(true);
  };

  return (
    <>
      <Overlay onClick={close} />
      <Popup>
        <CloseButton onClick={closeModal}>×</CloseButton>
        <PopupContent>
          <PopupHeader>
            <PolybotRecord
              style={{
                border:
                  robotData.stats.tier === "C"
                    ? "3px solid #B0C4DE"
                    : robotData.stats.tier === "B"
                    ? "3px solid rgba(0, 255, 127, 1)"
                    : "3px solid #defaultColor",
                boxShadow:
                  robotData.stats.tier === "C"
                    ? "rgba(176,196,222, 1) 0px 2px 16px 0px"
                    : robotData.stats.tier === "B"
                    ? "rgba(0, 255, 127, 1) 0px 2px 16px 0px"
                    : "defaultBoxShadow",
              }}
            >
              <h4 style={{ textAlign: "left" }}>{robotData.name}</h4>
              <RecordData>
                <h6 className="blue">Tier: </h6>
                <h6
                  style={{
                    color:
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : robotData.stats.tier === "B"
                        ? "#00FF7F"
                        : "FFF",
                  }}
                >
                  {robotData.stats.tier}
                </h6>
              </RecordData>
              <RecordData>
                <h6 className="blue">Manufacturer: </h6>
                <h6>{robotData.stats.manufacturer}</h6>
              </RecordData>
              <RecordData>
                <h6 className="blue">Chip: </h6>
                <h6>{robotData.stats.chip}</h6>
              </RecordData>
              <ContainerStatusData>
                <StatusData style={{ marginRight: "2px" }}>
                  <h6></h6>
                  <h6
                    style={{
                      color:
                        robotData.stats.tier === "C"
                          ? "#B0C4DE"
                          : robotData.stats.tier === "B"
                          ? "#00FF7F"
                          : "FFF",
                    }}
                  >
                    <StarDiv>
                      <StarIcon color="#FFD700" />
                    </StarDiv>
                  </h6>
                  <h6></h6>
                  <h6
                    style={{
                      color:
                        robotData.stats.tier === "C"
                          ? "#B0C4DE"
                          : robotData.stats.tier === "B"
                          ? "#00FF7F"
                          : "FFF",
                    }}
                  >
                    <StarrrDiv>
                      <ThreeStarsIcon color="#FFD700" />
                    </StarrrDiv>
                  </h6>
                </StatusData>
                <StatusData>
                  <h6>Attack:</h6>
                  <h6>{robotData.stats.attack.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.stats.attack.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Defense:</h6>
                  <h6>{robotData.stats.defence.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.stats.defence.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Health:</h6>
                  <h6>{robotData.stats.life.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.stats.life.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Agility:</h6>
                  <h6>{robotData.stats.agility.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.stats.agility.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Energy:</h6>
                  <h6>{robotData.stats.energy.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.stats.energy.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Total:</h6>
                  <h6>{robotData.stats.total.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.stats.total.upgraded}</h6>
                </StatusData>
              </ContainerStatusData>
            </PolybotRecord>

            <img src={robotData.robotBanner} />
          </PopupHeader>
          <Robot>
            <h2>GEARS</h2>
            <img className="robot" src={robotData.bigRobot} />
            <div
              className="chest"
              onClick={scrollToGear}
              style={{
                backgroundImage:
                  robotData.stats.tier === "C"
                    ? "url('assets/img/polybots/Gears/bg-gray.png')"
                    : "url('assets/img/polybots/Gears/bg-green.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img src={robotData.gears.chest} />
            </div>

            <div
              className="leftArm"
              onClick={scrollToGear}
              style={{
                backgroundImage:
                  robotData.stats.tier === "C"
                    ? "url('assets/img/polybots/Gears/bg-gray.png')"
                    : "url('assets/img/polybots/Gears/bg-green.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img src={robotData.gears.leftArm}></img>
            </div>
            <div
              className="legs"
              onClick={scrollToLegs}
              style={{
                backgroundImage:
                  robotData.stats.tier === "C"
                    ? "url('assets/img/polybots/Gears/bg-gray.png')"
                    : "url('assets/img/polybots/Gears/bg-green.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img src={robotData.gears.legs}></img>
            </div>
            <div
              className="head"
              onClick={scrollToGear}
              style={{
                backgroundImage:
                  robotData.stats.tier === "C"
                    ? "url('assets/img/polybots/Gears/bg-gray.png')"
                    : "url('assets/img/polybots/Gears/bg-green.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img src={robotData.gears.head}></img>
            </div>
            <div
              className="rightArm"
              onClick={scrollToGear}
              style={{
                backgroundImage:
                  robotData.stats.tier === "C"
                    ? "url('assets/img/polybots/Gears/bg-gray.png')"
                    : "url('assets/img/polybots/Gears/bg-green.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img src={robotData.gears.rightArm}></img>
            </div>
          </Robot>

          <h2>GEARS STATS</h2>

          <GearStats ref={GearRef}>
            <div className="gear-status-container">
              <div gear-status-container-img-title>
                <h3>Head</h3>
                <div
                  style={{
                    backgroundImage:
                      robotData.stats.tier === "C"
                        ? "url('assets/img/polybots/Gears/bg-gray.png')"
                        : "url('assets/img/polybots/Gears/bg-green.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <img src={robotData.gears.head}></img>
                </div>
              </div>
              <ContainerStatusData className="border-status">
                <StatusData style={{ marginRight: "10px" }}>
                  <h6></h6>
                  <h6>
                    <StarIcon color="#FFD700" />
                  </h6>
                  <h6></h6>
                  <h6>
                    <ThreeStarsIcon color="#FFD700" />
                  </h6>
                </StatusData>
                <StatusData>
                  <h6>Attack:</h6>
                  <h6>{robotData.gearStats[0].attack.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[0].attack.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Defense:</h6>
                  <h6>{robotData.gearStats[0].defence.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[0].defence.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Health:</h6>
                  <h6>{robotData.gearStats[0].life.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[0].life.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Agility:</h6>
                  <h6>{robotData.gearStats[0].agility.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[0].agility.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Energy:</h6>
                  <h6>{robotData.gearStats[0].energy.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[0].energy.upgraded}</h6>
                </StatusData>
              </ContainerStatusData>
            </div>
            <div className="gear-status-container">
              <div className="gear-status-container-img-title">
                <h3>Chest</h3>
                <div
                  style={{
                    backgroundImage:
                      robotData.stats.tier === "C"
                        ? "url('assets/img/polybots/Gears/bg-gray.png')"
                        : "url('assets/img/polybots/Gears/bg-green.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <img src={robotData.gears.chest}></img>
                </div>
              </div>
              <ContainerStatusData className="border-status">
                <StatusData style={{ marginRight: "10px" }}>
                  <h6></h6>
                  <h6>
                    <StarIcon color="#FFD700" />
                  </h6>
                  <h6></h6>
                  <h6>
                    <ThreeStarsIcon color="#FFD700" />
                  </h6>
                </StatusData>
                <StatusData>
                  <h6>Attack:</h6>
                  <h6>{robotData.gearStats[1].attack.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[1].attack.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Defense:</h6>
                  <h6>{robotData.gearStats[1].defence.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[1].defence.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Health:</h6>
                  <h6>{robotData.gearStats[1].life.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[1].life.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Agility:</h6>
                  <h6>{robotData.gearStats[1].agility.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[1].agility.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Energy:</h6>
                  <h6>{robotData.gearStats[1].energy.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[1].energy.upgraded}</h6>
                </StatusData>
              </ContainerStatusData>
            </div>
            <div className="gear-status-container" ref={LegsRef}>
              <div className="gear-status-container-img-title">
                <h3>Right-Arm</h3>
                <div
                  style={{
                    backgroundImage:
                      robotData.stats.tier === "C"
                        ? "url('assets/img/polybots/Gears/bg-gray.png')"
                        : "url('assets/img/polybots/Gears/bg-green.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <img src={robotData.gears.leftArm}></img>
                </div>
              </div>
              <ContainerStatusData className="border-status">
                <StatusData style={{ marginRight: "10px" }}>
                  <h6></h6>
                  <h6>
                    <StarIcon color="#FFD700" />
                  </h6>
                  <h6></h6>
                  <h6>
                    <ThreeStarsIcon color="#FFD700" />
                  </h6>
                </StatusData>
                <StatusData>
                  <h6>Attack:</h6>
                  <h6>{robotData.gearStats[2].attack.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[2].attack.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Defense:</h6>
                  <h6>{robotData.gearStats[2].defence.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[2].defence.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Health:</h6>
                  <h6>{robotData.gearStats[2].life.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[2].life.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Agility:</h6>
                  <h6>{robotData.gearStats[2].agility.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[2].agility.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Energy:</h6>
                  <h6>{robotData.gearStats[2].energy.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[2].energy.upgraded}</h6>
                </StatusData>
              </ContainerStatusData>
            </div>
            <div className="gear-status-container">
              <div className="gear-status-container-img-title">
                <h3>Left-Arm</h3>
                <div
                  style={{
                    backgroundImage:
                      robotData.stats.tier === "C"
                        ? "url('assets/img/polybots/Gears/bg-gray.png')"
                        : "url('assets/img/polybots/Gears/bg-green.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <img src={robotData.gears.rightArm}></img>
                </div>
              </div>
              <ContainerStatusData className="border-status">
                <StatusData style={{ marginRight: "10px" }}>
                  <h6></h6>
                  <h6>
                    <StarIcon color="#FFD700" />
                  </h6>
                  <h6></h6>
                  <h6>
                    <ThreeStarsIcon color="#FFD700" />
                  </h6>
                </StatusData>
                <StatusData>
                  <h6>Attack:</h6>
                  <h6>{robotData.gearStats[3].attack.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[3].attack.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Defense:</h6>
                  <h6>{robotData.gearStats[3].defence.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[3].defence.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Health:</h6>
                  <h6>{robotData.gearStats[3].defence.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[3].defence.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Agility:</h6>
                  <h6>{robotData.gearStats[3].agility.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[3].agility.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Energy:</h6>
                  <h6>{robotData.gearStats[3].energy.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[3].energy.upgraded}</h6>
                </StatusData>
              </ContainerStatusData>
            </div>
            <div className="gear-status-container">
              <div className="gear-status-container-img-title">
                <h3>Legs</h3>
                <div
                  style={{
                    backgroundImage:
                      robotData.stats.tier === "C"
                        ? "url('assets/img/polybots/Gears/bg-gray.png')"
                        : "url('assets/img/polybots/Gears/bg-green.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "155px",
                  }}
                >
                  <img src={robotData.gears.legs}></img>
                </div>
              </div>
              <ContainerStatusData className="border-status">
                <StatusData style={{ marginRight: "10px" }}>
                  <h6></h6>
                  <h6>
                    <StarIcon color="#FFD700" />
                  </h6>
                  <h6></h6>
                  <h6>
                    <ThreeStarsIcon color="#FFD700" />
                  </h6>
                </StatusData>
                <StatusData>
                  <h6>Attack:</h6>
                  <h6>{robotData.gearStats[4].attack.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[4].attack.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Defense:</h6>
                  <h6>{robotData.gearStats[4].defence.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[4].defence.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Health:</h6>
                  <h6>{robotData.gearStats[4].life.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[4].life.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Agility:</h6>
                  <h6>{robotData.gearStats[4].agility.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[4].agility.upgraded}</h6>
                </StatusData>
                <StatusData>
                  <h6>Energy:</h6>
                  <h6>{robotData.gearStats[4].energy.base}</h6>
                  <ArrowIcon
                    color={
                      robotData.stats.tier === "C"
                        ? "#B0C4DE"
                        : "rgba(0, 255, 127, 1)"
                    }
                    size={40}
                  />
                  <h6>{robotData.gearStats[4].energy.upgraded}</h6>
                </StatusData>
              </ContainerStatusData>
            </div>
          </GearStats>

          <RobotChip>
            <h2>CHIP</h2>
            <div>
              <h3>{robotData.stats.chip}</h3>
              <img
                src={robotData.chip}
                onClick={() => window.open("/wiki?section=chips", "_blank")}
              ></img>
            </div>

            <div>
              <h6>
                <span>Special: </span> {robotData.special.name}
              </h6>
              <h6>
                <span>Damage: </span> {robotData.special.damage}
              </h6>
              <h6>
                <span>Cost: </span> {robotData.special.cost} Energy
              </h6>
              <h6>
                <span>Description: </span> {robotData.special.description}
              </h6>
            </div>
          </RobotChip>

          <h2 style={{ marginBottom: "3rem" }}>SKILLS</h2>

          <RobotSkills>
            <div className="icon">
              <div
                style={{
                  backgroundImage:
                    robotData.stats.tier === "C"
                      ? "url('assets/img/polybots/Gears/bg-gray.png')"
                      : "url('assets/img/polybots/Gears/bg-green.png')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <img src={robotData.gears.leftArm}></img>
              </div>
            </div>
            <img className="imgicon" src={robotData.skills[0].icon}></img>
            <div>
            <h6>
                <span>Name: </span> {robotData.skills[0].name}
              </h6>
              <h6>
                <span>Damage: </span> {robotData.skills[0].damage}
              </h6>
              <h6>
                <span>Type: </span> {robotData.skills[0].type}
              </h6>
              <h6>
                <span>Cost: </span> {robotData.skills[0].energy}
              </h6>
              <h6>
                <span>Effect: </span> {robotData.skills[0].effect}
              </h6>
            </div>
            <img className="imgicon" src={robotData.skills[1].icon}></img>
            <div>
              <h6>
                <span>Name: </span> {robotData.skills[1].name}
              </h6>
              <h6>
                <span>Damage: </span> {robotData.skills[1].damage}
              </h6>
              <h6>
                <span>Type: </span> {robotData.skills[1].type}
              </h6>
              <h6>
                <span>Cost: </span> {robotData.skills[1].energy}
              </h6>
              <h6>
                <span>Effect: </span> {robotData.skills[1].effect}
              </h6>
            </div>
          </RobotSkills>

          <h2 style={{ marginBottom: "3rem" }}>PASSIVE</h2>

          <RobotSkills>
            <div className="icon">
              <div
                style={{
                  backgroundImage:
                    robotData.stats.tier === "C"
                      ? "url('assets/img/polybots/Gears/bg-gray.png')"
                      : "url('assets/img/polybots/Gears/bg-green.png')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <img src={robotData.gears.chest}></img>
              </div>
            </div>
              <img className="imgicon"  src={robotData.passive.icon}></img>
            <div className="chest">
              <h6>
                <span>Name: </span> {robotData.passive.name}
              </h6>
              <h6>
                <span>Damage: </span> None
              </h6>
              <h6>
                <span>Type: </span> {robotData.passive.type}
              </h6>
              <h6>
                <span>Cost: </span> 1
              </h6>
              <h6>
                <span>Effect: </span> {robotData.passive.effect}
              </h6>
            </div>
          </RobotSkills>
          <h2>HOW TO GET</h2>
          <RobotBoxes>
            {boxes.map((box, index) => (
              <RobotChip key={index}>
                <div>
                  <h3>{box.name}</h3>
                  <img
                    src={box.img}
                    alt={`${box.name} Box`}
                    onClick={() => window.open("/wiki?section=store", "_blank")}
                  />
                </div>
                <div className="no-margin">
                  <h6>
                    <span>Price: </span> {box.price} Gems
                  </h6>
                  <h6>
                    <span>1 Star chance: </span> {box.starChances.oneStar}
                  </h6>
                  <h6>
                    <span>2 Star chance: </span> {box.starChances.twoStar}
                  </h6>
                  <h6>
                    <span>3 Star chance: </span> {box.starChances.threeStar}
                  </h6>
                </div>
              </RobotChip>
            ))}
          </RobotBoxes>
        </PopupContent>
      </Popup>
    </>
  );
}
