import { items } from "../data/itemsdata";

const AlphaNumericSequence = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

// Função para calcular o percentual truncando os valores decimais
function calculatePercent(character) {
  const index = AlphaNumericSequence.indexOf(character.toUpperCase());
  if (index === -1) return 0;
  const percent = (index / (AlphaNumericSequence.length - 1)) * 100;
  // Truncando os valores decimais sem arredondar
  return Math.floor(percent);
}

function getPartBonusPercents(dna) {
  dna = dna.toUpperCase();
  const atk = dna.length > 0 ? calculatePercent(dna[0]) : 0;
  const def = dna.length > 1 ? calculatePercent(dna[1]) : 0;
  const hp = dna.length > 2 ? calculatePercent(dna[2]) : 0;
  const spd = dna.length > 3 ? calculatePercent(dna[3]) : 0;
  const enrg = dna.length > 4 ? calculatePercent(dna[4]) : 0;
  return { atk, def, hp, spd, enrg };
}

function getItemById(itemId) {
  return items.find((item) => item.itemID === itemId);
}

export function getItemAttributesWithBonus(stars, partId, itemId) {
  const { atk, def, hp, spd, enrg } = getPartBonusPercents(partId);

  // Recupera o item da lista
  const item = getItemById(itemId);
  if (!item) {
    throw new Error(`Item with ID ${itemId} not found.`);
  }
  const starKey = `${stars}star`;
  const nextStar = stars + 1;
  const nextStarKey = nextStar > 3 ? null : `${nextStar}star`;

  // Extrair os valores base
  const baseAttack = item.stats.attack[starKey];
  const baseDefense = item.stats.defense[starKey];
  const baseHealth = item.stats.health[starKey];
  const baseAgility = item.stats.agility[starKey];
  const baseEnergy = item.stats.energy[starKey];

  let atkBonus = 0,
    defBonus = 0,
    hpBonus = 0,
    spdBonus = 0,
    energyBonus = 0;

  // Evita calcular bônus para itens de 3 estrelas
  if (stars !== 3 && nextStarKey) {
    // Cálculo dos bônus diretamente
    atkBonus = Math.round(
      ((item.stats.attack[nextStarKey] - item.stats.attack[starKey]) / 100) *
        atk
    );
    defBonus = Math.round(
      ((item.stats.defense[nextStarKey] - item.stats.defense[starKey]) / 100) *
        def
    );
    hpBonus = Math.round(
      ((item.stats.health[nextStarKey] - item.stats.health[starKey]) / 100) * hp
    );
    spdBonus = Math.round(
      ((item.stats.agility[nextStarKey] - item.stats.agility[starKey]) / 100) *
        spd
    );
    energyBonus = Math.round(
      ((item.stats.energy[nextStarKey] - item.stats.energy[starKey]) / 100) *
        enrg
    );
  }

  const attack = baseAttack + atkBonus;
  const defense = baseDefense + defBonus;
  const health = baseHealth + hpBonus;
  const agility = baseAgility + spdBonus;
  const energy = baseEnergy + energyBonus;

  return {
    attack,
    defense,
    health,
    agility,
    energy,
  };
}
