import React, { useState, useEffect } from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import Trophy from "../../assets/img/icon/Trophy";
import "./RankingMoonbeam.css";
import ConfettiComponent from "../../components/confetti/Confetti";
import styled from "styled-components";

const HoverImage = styled.img`
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 100%;
  cursor: pointer;
  max-width: 150px;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  @media (max-width: 450px) {
    max-width: 100px;
  }
`;

const DetailView = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 2%;
  justify-content: space-between;
  margin-top: 10px;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h6 {
    margin: 5px 0;
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 12px;
    }
  }
`;

function RankingMoonbeam() {

  const rankingData = [
    {
      refer: "TheNestDAO",
      invites: 56,
      downloads: 14,
      active_players: 9,
      score: 111,
    },
    {
      refer: "SmartSEC",
      invites: 41,
      downloads: 11,
      active_players: 7,
      score: 87,
    },
    {
      refer: "ZeroParalelo",
      invites: 35,
      downloads: 14,
      active_players: 8,
      score: 87,
    },
    {
      refer: "Garbim1",
      invites: 31,
      downloads: 13,
      active_players: 8,
      score: 87,
    },
    {
      refer: "SuraBrasil",
      invites: 40,
      downloads: 8,
      active_players: 5,
      score: 71,
    },
    {
      refer: "GameDrop",
      invites: 34,
      downloads: 8,
      active_players: 5,
      score: 65,
    },
    { refer: "Trexx", invites: 12, downloads: 8, active_players: 2, score: 55 },
    {
      refer: "YieldHackers",
      invites: 48,
      downloads: 7,
      active_players: 2,
      score: 54,
    },
    {
      refer: "RodCacioli",
      invites: 20,
      downloads: 6,
      active_players: 2,
      score: 35,
    },
    {
      refer: "Degenerados",
      invites: 14,
      downloads: 2,
      active_players: 1,
      score: 25,
    },
    {
      refer: "CSGacademy",
      invites: 16,
      downloads: 2,
      active_players: 1,
      score: 17,
    },
    {
      refer: "guiertuan",
      invites: 12,
      downloads: 4,
      active_players: 1,
      score: 17,
    },
    {
      refer: "BrunoShibasaki",
      invites: 11,
      downloads: 3,
      active_players: 2,
      score: 17,
    },
    {
      refer: "Emilybastida",
      invites: 3,
      downloads: 1,
      active_players: 1,
      score: 9,
    },
    {
      refer: "RecycleMinds",
      invites: 8,
      downloads: 0,
      active_players: 0,
      score: 5,
    },
    {
      refer: "CryptoNight",
      invites: 5,
      downloads: 0,
      active_players: 0,
      score: 5,
    },
    { refer: "iBEED", invites: 2, downloads: 0, active_players: 0, score: 5 },
  ];

  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(null);

  useEffect(() => {
    const Loading = async () => {
      setIsLoading(false);
      setTotalUsers(1000);
    };

    setTimeout(() => {
      Loading();
    }, 800);
  }, []);

  const handleCommunityClick = (index) => {
    setSelectedCommunity(selectedCommunity === index ? null : index);
  };

  const maxUsers = 1000;
  const progressPercentage = (totalUsers / maxUsers) * 100;

  return (
    <>
      <HeaderTwo />
      <main>
        <section className="rank-bg">
          <div className="container">
            <div className="ranking-moonbeam-content">
              {/* <div className="ranking-moonbeam-progress-bar">
                <h2>
                  {isLoading ? "Loading..." : `Congratulations Alpha Players `}
                  {!isLoading && (
                    <>
                      <ConfettiComponent active={true} />
                      <h1 style={{ color: "#40a5f8", fontWeight: "900" }}>
                        1000/1000
                      </h1>
                    </>
                  )}
                </h2>
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
              </div> */}
              {/* {!isLoading && (
                <>
                  <HoverImage
                    src="assets/img/partners/comunnitys/9.png"
                    style={{
                      maxWidth: "200px",
                      marginBottom: "-50px",
                      marginTop: "-80px",
                    }}
                    alt=""
                  />
                  <h4 style={{ marginTop: "-20px", marginBottom: "20px" }}>
                    IS THE WINNER
                  </h4>
                </>
              )} */}
              <div className="ranking-moonbeam-table">
                <ul className="ranking-moonbeam-list">
                  <li className="ranking-moonbeam-header ranking-moonbeam-row-header">
                    <div>
                      <h4>Rank</h4>
                    </div>
                    <div>
                      <h4>Community</h4>
                    </div>
                    <div>
                      <h4>Score</h4>
                    </div>
                  </li>
                  {isLoading ? (
                    <div>
                      <div className="container">
                        <div className="loading-container">
                          <div className="loading-wrapper">
                            <h1 className="loading-title">Loading...</h1>
                            <div className="loading-spinner"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    rankingData.map((community, index) => (
                      <React.Fragment key={index}>
                        <li
                          className="ranking-moonbeam-row"
                          onClick={() => handleCommunityClick(index)}
                          style={{
                            boxShadow:
                              index + 1 === 1 ? "0px 0px 15px #89CFF0" : "none",
                            transform: index + 1 === 1 ? "scale(1.03)" : "",
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Trophy
                              className="trophy-icon"
                              style={{
                                width: isSmallScreen ? "16px" : "24px",
                                height: isSmallScreen ? "16px" : "24px",
                                fill:
                                  index + 1 === 1
                                    ? "#FFD700"
                                    : index + 1 === 2
                                    ? "#C0C0C0"
                                    : index + 1 === 3
                                    ? "#CD7F32"
                                    : "transparent",
                              }}
                            />
                            <h6
                              style={{ marginLeft: "5px", marginRight: "10px" }}
                            >
                              {index + 1}
                            </h6>
                          </div>
                          <div>
                            <h6>{community.refer}</h6>
                          </div>
                          <div>
                            <h6>{community.score}</h6>
                          </div>
                        </li>
                        {selectedCommunity === index && (
                          <DetailView>
                            <h6>
                              Invites: ({community.invites} x 1) ={" "}
                              {community.invites} pts
                            </h6>
                            <h6>
                              Downloads: ({community.downloads} x 2) ={" "}
                              {community.downloads * 2} pts
                            </h6>
                            <h6>
                              Players: ({community.active_players} x 3) ={" "}
                              {community.active_players * 3} pts
                            </h6>
                          </DetailView>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default RankingMoonbeam;
