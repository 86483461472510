import React, { useEffect } from "react";
import confetti from "canvas-confetti";

const ConfettiComponent = ({ active, repeat = 7, delay = 1000 }) => {
  useEffect(() => {
    if (active) {
      let interval;
      let count = 0;

      const getRandomColor = () => {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      };

      const launchConfetti = () => {
        const colors = Array.from({ length: 6 }, getRandomColor);

        confetti({
          particleCount: 200,
          spread: 150,
          origin: { x: 0.5, y: 0.5 },
          colors,
          gravity: 0.5,
          ticks: 300,
          zIndex: 999,
        });
        count += 1;
        if (count >= repeat) {
          clearInterval(interval);
        }
      };

      launchConfetti(); // Trigger immediately
      interval = setInterval(launchConfetti, delay);

      return () => clearInterval(interval);
    }
  }, [active, repeat, delay]);

  return null;
};

export default ConfettiComponent;
