import React from "react";
import styled from "styled-components";

const Titles = ({ selectedTitle = null }) => {
  const titles = [
    {
      title: "Bronze",
      tiers: [
        {
          tier: "I",
          level: 1,
          points: "0",
          image: "/assets/img/wiki/tiers-rank/Bronze_1.png",
        },
        {
          tier: "II",
          level: 2,
          points: "100",
          image: "/assets/img/wiki/tiers-rank/Bronze_2.png",
        },
        {
          tier: "III",
          level: 3,
          points: "200",
          image: "/assets/img/wiki/tiers-rank/Bronze_3.png",
        },
        {
          tier: "IV",
          level: 4,
          points: "350",
          image: "/assets/img/wiki/tiers-rank/Bronze_4.png",
        },
        {
          tier: "V",
          level: 5,
          points: "550",
          image: "/assets/img/wiki/tiers-rank/Bronze_5.png",
        },
      ],
    },
    {
      title: "Prata",
      tiers: [
        {
          tier: "I",
          level: 6,
          points: "800",
          image: "/assets/img/wiki/tiers-rank/Silver_1.png",
        },
        {
          tier: "II",
          level: 7,
          points: "1100",
          image: "/assets/img/wiki/tiers-rank/Silver_2.png",
        },
        {
          tier: "III",
          level: 8,
          points: "1500",
          image: "/assets/img/wiki/tiers-rank/Silver_3.png",
        },
        {
          tier: "IV",
          level: 9,
          points: "2000",
          image: "/assets/img/wiki/tiers-rank/Silver_4.png",
        },
        {
          tier: "V",
          level: 10,
          points: "2600",
          image: "/assets/img/wiki/tiers-rank/Silver_5.png",
        },
      ],
    },
    {
      title: "Ouro",
      tiers: [
        {
          tier: "I",
          level: 11,
          points: "3300",
          image: "/assets/img/wiki/tiers-rank/Gold_1.png",
        },
        {
          tier: "II",
          level: 12,
          points: "4100",
          image: "/assets/img/wiki/tiers-rank/Gold_2.png",
        },
        {
          tier: "III",
          level: 13,
          points: "5000",
          image: "/assets/img/wiki/tiers-rank/Gold_3.png",
        },
        {
          tier: "IV",
          level: 14,
          points: "6000",
          image: "/assets/img/wiki/tiers-rank/Gold_4.png",
        },
        {
          tier: "V",
          level: 15,
          points: "7100",
          image: "/assets/img/wiki/tiers-rank/Gold_5.png",
        },
      ],
    },
    {
      title: "Platina",
      tiers: [
        {
          tier: "I",
          level: 16,
          points: "8300",
          image: "/assets/img/wiki/tiers-rank/Platinum_1.png",
        },
        {
          tier: "II",
          level: 17,
          points: "9600",
          image: "/assets/img/wiki/tiers-rank/Platinum_2.png",
        },
        {
          tier: "III",
          level: 18,
          points: "11000",
          image: "/assets/img/wiki/tiers-rank/Platinum_3.png",
        },
        {
          tier: "IV",
          level: 19,
          points: "13000",
          image: "/assets/img/wiki/tiers-rank/Platinum_4.png",
        },
        {
          tier: "V",
          level: 20,
          points: "15000",
          image: "/assets/img/wiki/tiers-rank/Platinum_5.png",
        },
      ],
    },
    {
      title: "Diamante",
      tiers: [
        {
          tier: "I",
          level: 21,
          points: "17000",
          image: "/assets/img/wiki/tiers-rank/Diamond_1.png",
        },
        {
          tier: "II",
          level: 22,
          points: "18500",
          image: "/assets/img/wiki/tiers-rank/Diamond_2.png",
        },
        {
          tier: "III",
          level: 23,
          points: "20000",
          image: "/assets/img/wiki/tiers-rank/Diamond_3.png",
        },
        {
          tier: "IV",
          level: 24,
          points: "21500",
          image: "/assets/img/wiki/tiers-rank/Diamond_4.png",
        },
        {
          tier: "V",
          level: 25,
          points: "23000",
          image: "/assets/img/wiki/tiers-rank/Diamond_5.png",
        },
      ],
    },
    {
      title: "Pro",
      tiers: [
        {
          tier: "I",
          level: 26,
          title: "Regional ",
          image: "/assets/img/wiki/tiers-rank/Pro_1.png",
        },
        {
          tier: "II",
          level: 27,
          title: "Mundial",
          image: "/assets/img/wiki/tiers-rank/Pro_2.png",
        },
        {
          tier: "III",
          level: 28,
          title: "Elite",
          image: "/assets/img/wiki/tiers-rank/Pro_3.png",
        },
      ],
    },
  ];

  const displayedTitles =
    selectedTitle !== null &&
    selectedTitle >= 0 &&
    selectedTitle < titles.length
      ? [titles[selectedTitle]]
      : [];

  return (
    <Container>
      {displayedTitles.length > 0 ? (
        displayedTitles.map((titleData, index) => (
          <TitleContainer key={index}>
            <TitleName style={{ marginBottom: "60px" }}>
              {titleData.title}
            </TitleName>
            {titleData.tiers.map((tierData, tierIndex) => (
              <TierContainer key={tierIndex}>
                <LeftSide>
                  <TierName>Tier: {tierData.tier}</TierName>
                  <Medal
                    src={tierData.image}
                    alt={`${titleData.title} Medal Tier ${tierData.tier}`}
                  />
                </LeftSide>
                <RightSide>
                  <TitleInfo>
                    <HighlightedText>Rank: </HighlightedText> {tierData.level}
                  </TitleInfo>
                  <TitleInfo>
                    <HighlightedText>
                      {titleData.title === "Pro" ? "Title: " : "Points: "}
                    </HighlightedText>
                    {titleData.title === "Pro"
                      ? tierData.title
                      : tierData.points}
                  </TitleInfo>
                </RightSide>
              </TierContainer>
            ))}
          </TitleContainer>
        ))
      ) : (
        <NoTitleContainer>
          <TitleName>Ranks and Tiers</TitleName>
          {/* <PlaceHolder /> */}
          <Description>
            Ranks represent your progress and dedication in the game. As you
            participate in more matches and accumulate points, you will rise
            through different ranks, from Bronze to the prestigious Pro Player.
            Each rank has multiple levels that reflect your skill and
            commitment.
            <br />
            <br />
            <h3>How to Get Points:</h3>
            <br />
            <br />
            To rank up, you need to earn points by playing matches. Here's how
            it works:
            <ul>
              <li>
                <br />
                <HighlightedTexts>
                  Winning Ranked Matches:
                </HighlightedTexts>{" "}
                Earn XP (experience) and rank points (RP) by winning competitive
                games. The more you win, the faster you climb the ranks.
              </li>
              <li>
                <br />
                <HighlightedTexts>Losing Matches:</HighlightedTexts> If you lose
                a ranked match, you lose some of your rank points. But don't
                worry, you still earn XP, even when you lose!
              </li>
              <li>
                <br />
                <HighlightedTexts>Game Modes:</HighlightedTexts> Ranked mode is
                where you gain and lose RP, while in Duel and Casual modes you
                only gain XP, which doesn't affect your rank.
              </li>
              <li>
                <br />
                <HighlightedTexts>Special Events:</HighlightedTexts> In modes
                like Festival, both players (winner and loser) earn festival
                points without affecting their rank or XP.
              </li>
            </ul>
            <br />
            <HighlightedTexts>How Rank Points are Calculated:</HighlightedTexts>
            <br />
            <br />
            When you play ranked matches, your rank points (RP) are calculated
            as follows:
            <ul> 
              <br />
              <li>
                <HighlightedTexts>Victory in Ranked Matches: </HighlightedTexts>
                When you win a ranked match, you gain RP equal to the XP you
                earned. For example, if you earned 100 XP, you also gain 100 RP.
              </li>
              <br />
              <li>
                <HighlightedTexts>Defeat in Ranked Matches: </HighlightedTexts>
                When you lose, you lose half of the RP the winner gained. For
                example, if the winner gained 100 RP, you would lose 50 RP.
                However, you still earn XP for participating.
              </li>
              <br />
              <li>
                <HighlightedTexts>Game Mode: </HighlightedTexts>
                Ranked mode is the only mode where you can gain or lose RP. In
                Duel and Casual modes, you only earn XP.
              </li>
              <br />
              <li>
                <HighlightedTexts>Special Events: </HighlightedTexts>
                During special events, both the winner and loser earn points
                without affecting their rank or XP.
              </li>
              <br />
            </ul>
            Keep playing and improving your skills to rank up and reach the
            highest levels!
          </Description>
        </NoTitleContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;

  @media (max-width: 766px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 30px;
`;

const TierContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  gap: 30px;

  @media (max-width: 766px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
`;

const HighlightedTexts = styled.span`
  font-size: 1.1em;
  font-weight: bold;
  color: #40a5f8;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightSide = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

const TierName = styled.h4`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const HighlightedText = styled.span`
  font-size: 1.1em;
  font-weight: bold;
  color: #40a5f8;
`;

const Medal = styled.img`
  width: 150px;
  height: 150px;
`;

const TitleInfo = styled.p`
  font-size: 1em;
  margin: 3px 0;
`;

const NoTitleContainer = styled.div`
  text-align: center;
`;

const TitleName = styled.h2`
  font-size: 3em;
  margin-bottom: 20px;

  @media (max-width: 766px) {
    font-size: 2.5em;
  }
`;

const Description = styled.p`
  font-size: 1em;
  margin-top: 20px;
  line-height: 1.5;
  text-align: left;

  @media (max-width: 766px) {
    text-align: justify;
  }
`;

const PlaceHolder = styled.div`
  width: 100%;
  height: 350px;
  background-color: #f5f5f5;
`;

export default Titles;
