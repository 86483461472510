import React from "react";
import styled from "styled-components";

const Blockchain = ({ selectedChain }) => {
  const Chains = [
    {
      name: "Moonbeam",
      description:
        "Moonbeam is a blockchain platform that extends the traditional capabilities of Ethereum, offering enhanced smart contract compatibility across multiple chains. Built on the Polkadot ecosystem, Moonbeam emphasizes interoperability, allowing developers to migrate Ethereum-based decentralized applications (dApps) with minimal modifications. Leveraging Polkadot's security and scalability, Moonbeam opens up new possibilities for cross-chain integration, making smart contract development more flexible and powerful.",
    },
    {
      name: "Immutable",
      description:
        "Immutable is a layer-2 scaling solution specifically designed for NFTs on the Ethereum blockchain. It aims to provide a seamless environment for fast, secure, and carbon-neutral NFT minting, trading, and ownership, all without the burden of gas fees. Tailored for both gamers and developers, Immutable enhances user experience by enabling smooth, high-speed transactions and the creation of digital assets on the Ethereum network without compromising security.",
    },
    {
      name: "ICP",
      description:
        "The Internet Computer (ICP) is a revolutionary blockchain that expands the scope of the internet by enabling smart contracts to run directly on the web. Its unique architecture scales computational power and data storage, creating a fast, decentralized environment for running applications without reliance on traditional cloud infrastructure. This allows for the creation of entirely decentralized web applications with robust scalability and security, pushing the boundaries of what blockchain technology can achieve.",
    },
    {
      name: "Mint - NFT",
      description:
        "Minting an NFT refers to the process of creating a new, unique digital asset on the blockchain. This process involves registering a piece of digital content, such as artwork, music, or other media, as a non-fungible token (NFT), giving it a distinct identity and ensuring its authenticity and ownership on the blockchain. Once minted, the NFT becomes a tradeable asset within the blockchain ecosystem.",
    },
    {
      name: "Withdraw - NFT",
      description:
        "Withdrawing an NFT is the process of transferring a non-fungible token from a platform, marketplace, or custodial environment to your personal crypto wallet. By doing so, you gain full ownership and control of the NFT, enabling you to hold, transfer, or sell the asset as you see fit, while ensuring its security outside of centralized platforms.",
    },
    {
      name: "Deposit - NFT",
      description:
        "Depositing an NFT involves moving a non-fungible token from your personal wallet into a platform or marketplace where it can be traded, sold, or interacted with. This process allows the NFT to become available for transaction within a specific environment, facilitating market activities such as auctions or direct sales.",
    },
  ];

  const openMarketPlace = () => {
    window.open(
      "https://rarible.com/collection/moonbeam/0xe5bc29bc98faaae718f200126dfd341702163477/items",
      "_blank"
    );
  };

  const openContract = () => {
    window.open(
      "https://moonscan.io/token/0xE5bc29Bc98faAAE718F200126Dfd341702163477",
      "_blank"
    );
  };

  return (
    <Container>
      {selectedChain != null ? (
        <>
          <Title>{Chains[selectedChain].name}</Title>
          <p>{Chains[selectedChain].description}</p>
          {Chains[selectedChain].name === "Moonbeam" && (
            <ButtonContainer>
              <Button onClick={openContract}>Polybots Contract</Button>
              <Button onClick={openMarketPlace}>MarketPlace</Button>
            </ButtonContainer>
          )}
          {Chains[selectedChain].name === "Mint - NFT" && (
            <>
              <Title style={{ marginTop: "40px", fontSize: "1.5em" }}>
                How to Mint in <HighlightedText>Website</HighlightedText>
              </Title>
              <Tutorial>
                <img
                  src="/assets/img/wiki/blockchain/mint-site.bmp"
                  alt="Mint no site"
                />
                <Steps>
                  <li>
                    <Span>1: </Span> Log into the garage
                  </li>
                  <li>
                    <Span>2: </Span> Click on inventory
                  </li>
                  <li>
                    <Span>3: </Span> Select the part
                  </li>
                  <li>
                    <Span>4: </Span> Wait for the Serial and DNA to be loaded
                  </li>
                  <li>
                    <Span>5: </Span> Select the Network
                  </li>
                  <li>
                    <Span>6: </Span> Then confirm the price and mint
                  </li>
                  <li>
                    <Span>7: </Span> Click the mint button
                  </li>
                </Steps>
              </Tutorial>
              <Title style={{ marginTop: "40px", fontSize: "1.5em" }}>
                How to Mint in <HighlightedText>Game</HighlightedText>
              </Title>
              <Tutorial>
                <img
                  src="/assets/img/wiki/blockchain/mint-game.jpg"
                  alt="Mint in game"
                />
                <Steps>
                  <li>
                    <Span>1: </Span> Click on inventory
                  </li>
                  <li>
                    <Span>2: </Span> Select the part minted
                  </li>
                  <li>
                    <Span>3: </Span> Wait for the Serial and DNA to be loaded
                  </li>
                  <li>
                    <Span>4: </Span> Select the Network
                  </li>
                  <li>
                    <Span>5: </Span> Then confirm the price and mint
                  </li>
                  <li>
                    <Span>6: </Span> Click the mint button
                  </li>
                </Steps>
              </Tutorial>
            </>
          )}
          {Chains[selectedChain].name === "Withdraw - NFT" && (
            <>
              <Title style={{ marginTop: "40px", fontSize: "1.5em" }}>
                How to Withdraw NFT from <HighlightedText>Website</HighlightedText>
              </Title>
              <Tutorial>
                <img
                  src="/assets/img/wiki/blockchain/wallet-site.bmp"
                  alt="Withdraw NFT from site"
                />
                <Steps>
                  <li>
                    <Span>1: </Span> Log into the garage
                  </li>
                  <li>
                    <Span>2: </Span> Click on wallets
                  </li>
                  <li>
                    <Span>3: </Span> Connect your wallet
                  </li>
                  <li>
                    <Span>4: </Span> Back to the garage and click in inventory
                  </li>
                  <li>
                    <Span>5: </Span> Select the part minted
                  </li>
                  <img
                  src="/assets/img/wiki/blockchain/withdraw-site.png"
                  alt="Withdraw NFT from site"
                />
                  <li>
                    <Span>6: </Span> Wait for the Serial and DNA to be loaded
                  </li>
                  <li>
                    <Span>7: </Span> Then confirm the price and withdraw
                  </li>
                  <li>
                    <Span>8: </Span> Click the withdraw button
                  </li>
                </Steps>
              </Tutorial>
              <Title style={{ marginTop: "40px", fontSize: "1.5em" }}>
                How to Withdraw NFT from <HighlightedText>Game</HighlightedText>
              </Title>
              <Tutorial>
                <img
                  src="/assets/img/wiki/blockchain/withdraw-game.jpg"
                  alt="Withdraw NFT from site"
                />
                <Steps>
                  <li>
                    <Span>1: </Span> Click on inventory
                  </li>
                  <li>
                    <Span>2: </Span> Select the part minted
                  </li>
                  <li>
                    <Span>3: </Span> Wait for the Serial and DNA to be loaded
                  </li>
                  <li>
                    <Span>4: </Span> Then confirm the price and withdraw
                  </li>
                  <li>
                    <Span>5: </Span> Click the withdraw button
                  </li>
                </Steps>
              </Tutorial>
            </>
          )}
        </>
      ) : (
        <>
          <Title>Blockchain Details</Title>
          <p>
            Blockchain technology is revolutionizing the way we secure and
            verify digital transactions by creating a decentralized,
            tamper-proof ledger that eliminates the need for intermediaries.
            Each transaction is recorded across a network of computers, ensuring
            trust, transparency, and security.
          </p>
          <p>
            At the heart of this innovation are NFTs (Non-Fungible Tokens),
            unique digital assets that certify ownership of one-of-a-kind items
            like art or game assets. Unlike cryptocurrencies, which are
            interchangeable, NFTs represent exclusive ownership and
            authenticity.
          </p>
          <p>
            In Polybots Rumble, the power of NFTs takes gameplay to the next
            level. Certain parts and items in the game are NFTs, giving players
            full ownership of in-game assets that they can trade, sell, or
            collect. This not only adds real-world value to these assets but
            also enhances the overall player experience by introducing rarity
            and true digital ownership.
          </p>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;

  @media (max-width: 400px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Span = styled.span`
  font-weight: bold;
  color: #40a5f8;
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;

  @media (max-width: 515px) {
    font-size: 2.5em;
  }

  @media (max-width: 420px) {
    font-size: 2em;
  }

  @media (max-width: 330px) {
    font-size: 1.8em;
  }
`;

const HighlightedText = styled.span`
  font-size: 1.1em;
  font-weight: bold;
  color: #40a5f8;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 420px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Button = styled.button`
  background-color: #3c98e5;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 300px;

  &:hover {
    background-color: #357bb2;
  }

  @media (max-width: 420px) {
    width: 200px;
  }
`;

const Tutorial = styled.div`
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
`;

const Steps = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 30px;

  li {
    margin-bottom: 10px;
    font-size: 1.1em;
  }
`;

export default Blockchain;
