import React from "react";

const WalletIcon = ({ size = 24, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="wallet">
      <path
        d="M22,5H21V2a1,1,0,0,0-1-1H4A3,3,0,0,0,1,4V20a3,3,0,0,0,3,3H22a1,1,0,0,0,1-1V6A1,1,0,0,0,22,5ZM3.31,3.29C3.7,2.88,2.7,3,19,3V5H4A1,1,0,0,1,3.31,3.29ZM4,21a1,1,0,0,1-1-1V6.83c.72.25.24.17,18,.17v4H18a3,3,0,0,0,0,6h3v4Zm17-6H18a1,1,0,0,1,0-2h3Z"
        fill="rgb(1, 31, 47)"
      />
    </g>
  </svg>
);

export default WalletIcon;
