export const polybots = {
  "YUT-002": {
    name: "YUT-002",
    robotBanner: "assets/img/robots/yellow.png",
    bigRobot: "assets/img/polybots/polybots/status/9.png",
    chip: "assets/img/polybots/Medals/Wasp/medal_wasp_gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/yellow-01.png",
      chest: "assets/img/polybots/Gears/yellow-02.png",
      leftArm: "assets/img/polybots/Gears/yellow-03.png",
      rightArm: "assets/img/polybots/Gears/yellow-04.png",
      legs: "assets/img/polybots/Gears/yellow-05.png",
    },
    stats: {
      tier: "C",
      manufacturer: "Yuto S. Corp.",
      chip: "Insect",
      attack: { base: 130, upgraded: 150 },
      defence: { base: 130, upgraded: 140 },
      life: { base: 450, upgraded: 500 },
      agility: { base: 130, upgraded: 140 },
      energy: { base: 60, upgraded: 60 },
      total: { base: 900, upgraded: 990 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 15, upgraded: 20 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 130, upgraded: 140 },
        agility: { base: 30, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "chest",
        attack: { base: 25, upgraded: 30 },
        defence: { base: 50, upgraded: 50 },
        life: { base: 130, upgraded: 140 },
        agility: { base: 20, upgraded: 20 },
        energy: { base: 20, upgraded: 20 },
      },
      {
        part: "leftArm",
        attack: { base: 35, upgraded: 40 },
        defence: { base: 10, upgraded: 15 },
        life: { base: 20, upgraded: 30 },
        agility: { base: 30, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 45, upgraded: 50 },
        defence: { base: 12, upgraded: 15 },
        life: { base: 20, upgraded: 30 },
        agility: { base: 20, upgraded: 20 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 10, upgraded: 10 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 150, upgraded: 160 },
        agility: { base: 30, upgraded: 40 },
        energy: { base: 10, upgraded: 10 },
      },
    ],
    special: {
      name: "Strong Shot",
      damage: "17-27 + Attributte bonus",
      cost: 60,
      description:
        "The Insect chip unleashes a precise and relentless attack, mimicking the persistent and focused nature of its namesake.",
      icon: "",
    },
    skills: [
      {
        name: "Fast Punch",
        damage: "1 - 5",
        type: "Melee",
        energy: 1,
        effect: "None",
        icon: "/assets/img/polybots/icons/Soco rápido.png",
      },
      {
        name: "Fast Shot",
        damage: "3 - 9",
        type: "Dist",
        energy: 2,
        effect: "None",
        icon: "/assets/img/polybots/icons/Tiro rápido.png",
      },
    ],
    passive: {
      name: "Simple Defense",
      effect: "Total Defense",
      type: "Melee",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "YUT-001": {
    name: "YUT-001",
    robotBanner: "assets/img/robots/blue.png",
    bigRobot: "assets/img/polybots/polybots/status/1.png",
    chip: "assets/img/polybots/Medals/Wasp/medal_wasp_gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/blue-01.png",
      chest: "assets/img/polybots/Gears/blue-02.png",
      leftArm: "assets/img/polybots/Gears/blue-03.png",
      rightArm: "assets/img/polybots/Gears/blue-04.png",
      legs: "assets/img/polybots/Gears/blue-05.png",
    },
    stats: {
      tier: "C",
      manufacturer: "Yuto S. Corp.",
      chip: "Insect",
      attack: { base: 130, upgraded: 150 },
      defence: { base: 130, upgraded: 140 },
      life: { base: 450, upgraded: 500 },
      agility: { base: 130, upgraded: 140 },
      energy: { base: 60, upgraded: 60 },
      total: { base: 900, upgraded: 990 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 15, upgraded: 20 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 130, upgraded: 140 },
        agility: { base: 30, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "chest",
        attack: { base: 25, upgraded: 30 },
        defence: { base: 50, upgraded: 50 },
        life: { base: 130, upgraded: 140 },
        agility: { base: 20, upgraded: 20 },
        energy: { base: 20, upgraded: 20 },
      },
      {
        part: "leftArm",
        attack: { base: 35, upgraded: 40 },
        defence: { base: 10, upgraded: 15 },
        life: { base: 20, upgraded: 30 },
        agility: { base: 30, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 45, upgraded: 50 },
        defence: { base: 12, upgraded: 15 },
        life: { base: 20, upgraded: 30 },
        agility: { base: 20, upgraded: 20 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 10, upgraded: 10 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 150, upgraded: 160 },
        agility: { base: 30, upgraded: 40 },
        energy: { base: 10, upgraded: 10 },
      },
    ],
    special: {
      name: "Strong Shot",
      damage: "17-27 + Attributte bonus",
      cost: 60,
      description:
        "The Insect chip unleashes a precise and relentless attack, mimicking the persistent and focused nature of its namesake.",
      icon: "",
    },
    skills: [
      {
        name: "Fast Punch",
        damage: "1 - 5",
        type: "Melee",
        energy: 1,
        effect: "None",
        icon: "/assets/img/polybots/icons/Soco rápido.png",
      },
      {
        name: "Fast Shot",
        damage: "3 - 9",
        type: "Dist",
        energy: 2,
        effect: "None",
        icon: "/assets/img/polybots/icons/Tiro rápido.png",
      },
    ],
    passive: {
      name: "Simple Defense",
      effect: "Total Defense",
      type: "Melee",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "TMN-380": {
    name: "TMN-380",
    robotBanner: "assets/img/robots/green.png",
    bigRobot: "assets/img/polybots/polybots/status/7.png",
    chip: "assets/img/polybots/Medals/Turtle/medal_turtle gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/green-01.png",
      chest: "assets/img/polybots/Gears/green-02.png",
      leftArm: "assets/img/polybots/Gears/green-03.png",
      rightArm: "assets/img/polybots/Gears/green-04.png",
      legs: "assets/img/polybots/Gears/green-05.png",
    },
    stats: {
      tier: "B",
      manufacturer: "Yuto S. Corp.",
      chip: "Turtle",
      attack: { base: 186, upgraded: 220 },
      defence: { base: 165, upgraded: 190 },
      life: { base: 430, upgraded: 460 },
      agility: { base: 130, upgraded: 149 },
      energy: { base: 80, upgraded: 80 },
      total: { base: 991, upgraded: 1099 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 30, upgraded: 35 },
        defence: { base: 30, upgraded: 35 },
        life: { base: 60, upgraded: 70 },
        agility: { base: 25, upgraded: 25 },
        energy: { base: 15, upgraded: 15 },
      },
      {
        part: "chest",
        attack: { base: 30, upgraded: 40 },
        defence: { base: 40, upgraded: 45 },
        life: { base: 130, upgraded: 140 },
        agility: { base: 20, upgraded: 30 },
        energy: { base: 25, upgraded: 25 },
      },
      {
        part: "leftArm",
        attack: { base: 53, upgraded: 60 },
        defence: { base: 30, upgraded: 35 },
        life: { base: 70, upgraded: 70 },
        agility: { base: 25, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 53, upgraded: 60 },
        defence: { base: 30, upgraded: 35 },
        life: { base: 70, upgraded: 70 },
        agility: { base: 25, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 20, upgraded: 25 },
        defence: { base: 35, upgraded: 40 },
        life: { base: 100, upgraded: 110 },
        agility: { base: 35, upgraded: 35 },
        energy: { base: 20, upgraded: 20 },
      },
    ],
    special: {
      name: "Oceanic Vortex",
      damage: "20 to 32 + Bonus Atributes",
      cost: 80,
      description:
        "Harnessing the Turtle's wisdom and patience, this chip releases a deep, swirling force, pulling enemies into a slow, inevitable defeat.",
      icon: "",
    },
    skills: [
      {
        name: "Fast Punch",
        damage: "1 - 5",
        type: "Melee",
        energy: 1,
        effect: "None",
        icon: "/assets/img/polybots/icons/Soco rápido.png",
      },
      {
        name: "Marine Pulse",
        damage: "5 - 15",
        type: "Dist",
        energy: 3,
        effect: "None",
        icon: "/assets/img/polybots/icons/Pulso Marinho.png",
      },
    ],
    passive: {
      name: "Chest Passive",
      effect: "More Defence",
      type: "Melee",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "ANK-590": {
    name: "ANK-590",
    robotBanner: "assets/img/robots/pink.png",
    bigRobot: "assets/img/polybots/polybots/status/5.png",
    chip: "assets/img/polybots/Medals/Tanuki/medal_tanuki gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/pink-01.png",
      chest: "assets/img/polybots/Gears/pink-02.png",
      leftArm: "assets/img/polybots/Gears/pink-03.png",
      rightArm: "assets/img/polybots/Gears/pink-04.png",
      legs: "assets/img/polybots/Gears/pink-05.png",
    },
    stats: {
      tier: "B",
      manufacturer: "Yuto S. Corp.",
      chip: "Tanuki",
      attack: { base: 145, upgraded: 175 },
      defence: { base: 125, upgraded: 140 },
      life: { base: 512, upgraded: 569 },
      agility: { base: 120, upgraded: 135 },
      energy: { base: 80, upgraded: 80 },
      total: { base: 991, upgraded: 1099 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 20, upgraded: 30 },
        defence: { base: 20, upgraded: 25 },
        life: { base: 80, upgraded: 90 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 15, upgraded: 15 },
      },
      {
        part: "chest",
        attack: { base: 20, upgraded: 25 },
        defence: { base: 30, upgraded: 35 },
        life: { base: 150, upgraded: 160 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 25, upgraded: 25 },
      },
      {
        part: "leftArm",
        attack: { base: 40, upgraded: 45 },
        defence: { base: 25, upgraded: 25 },
        life: { base: 90, upgraded: 100 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 40, upgraded: 45 },
        defence: { base: 25, upgraded: 25 },
        life: { base: 90, upgraded: 100 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 20, upgraded: 25 },
        defence: { base: 25, upgraded: 30 },
        life: { base: 110, upgraded: 119 },
        agility: { base: 30, upgraded: 35 },
        energy: { base: 20, upgraded: 20 },
      },
    ],
    special: {
      name: "Luminary Trap",
      damage: "22 to 28 + Bonus Atributes",
      cost: 80,
      description:
        "Channeling the trickster spirit of the Tanuki, this chip creates a dazzling diversion, catching foes off guard with its mystical energy.",
      icon: "",
    },
    skills: [
      {
        name: "Stealth Shot",
        damage: "5 - 15",
        type: "Dist",
        energy: 3,
        effect: "None",
        icon: "/assets/img/polybots/icons/Tiro furtivo.png",
      },
      {
        name: "Moves Scanner",
        damage: "3 - 9",
        type: "Melee",
        energy: 2,
        effect: "Attack that shows the opponent's list of moves",
        icon: "/assets/img/polybots/icons/Mover Scanner.png",
      },
    ],
    passive: {
      name: "Chest Passive",
      effect: "More Defence",
      type: "Melee",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "FNX-210": {
    name: "FNX-210",
    robotBanner: "assets/img/robots/red.png",
    bigRobot: "assets/img/polybots/polybots/status/3.png",
    chip: "assets/img/polybots/Medals/Fenix/medal_fenix gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/red-01.png",
      chest: "assets/img/polybots/Gears/red-02.png",
      leftArm: "assets/img/polybots/Gears/red-03.png",
      rightArm: "assets/img/polybots/Gears/red-04.png",
      legs: "assets/img/polybots/Gears/red-05.png",
    },
    stats: {
      tier: "B",
      manufacturer: "Yuto S. Corp.",
      chip: "Phoenix",
      attack: { base: 221, upgraded: 260 },
      defence: { base: 150, upgraded: 180 },
      life: { base: 400, upgraded: 430 },
      agility: { base: 140, upgraded: 149 },
      energy: { base: 80, upgraded: 80 },
      total: { base: 991, upgraded: 1099 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 35, upgraded: 40 },
        defence: { base: 25, upgraded: 30 },
        life: { base: 50, upgraded: 60 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 15, upgraded: 15 },
      },
      {
        part: "chest",
        attack: { base: 40, upgraded: 50 },
        defence: { base: 45, upgraded: 50 },
        life: { base: 140, upgraded: 150 },
        agility: { base: 20, upgraded: 24 },
        energy: { base: 25, upgraded: 25 },
      },
      {
        part: "leftArm",
        attack: { base: 65, upgraded: 75 },
        defence: { base: 25, upgraded: 30 },
        life: { base: 60, upgraded: 60 },
        agility: { base: 20, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 65, upgraded: 75 },
        defence: { base: 25, upgraded: 30 },
        life: { base: 60, upgraded: 60 },
        agility: { base: 20, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 16, upgraded: 20 },
        defence: { base: 30, upgraded: 40 },
        life: { base: 90, upgraded: 100 },
        agility: { base: 40, upgraded: 40 },
        energy: { base: 20, upgraded: 20 },
      },
    ],
    special: {
      name: "Scarlet Explosion",
      damage: "25 to 30 + Bonus Atributes",
      cost: 80,
      description:
        "Like a fiery rebirth, the Phoenix chip engulfs enemies in a blazing assault, symbolizing the creature's legendary resurrection.",
      icon: "",
    },
    skills: [
      {
        name: "Fast Shot",
        damage: "3 - 9",
        type: "Dist",
        energy: 2,
        effect: "None",
        icon: "/assets/img/polybots/icons/Tiro rápido.png",
      },
      {
        name: "Thunderous Burst",
        damage: "5 - 15",
        type: "Dist",
        energy: 3,
        effect: "None",
        icon: "/assets/img/polybots/icons/Explosão estrondosa.png",
      },
    ],
    passive: {
      name: "Chest Passive",
      effect: "More Defence",
      type: "Melee",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "NXT-480": {
    name: "NXT-480",
    robotBanner: "assets/img/robots/tiger.png",
    bigRobot: "assets/img/polybots/polybots/status/4.png",
    chip: "assets/img/polybots/Medals/Tiger/medal_tiger gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/tiger-01.png",
      chest: "assets/img/polybots/Gears/tiger-02.png",
      leftArm: "assets/img/polybots/Gears/tiger-03.png",
      rightArm: "assets/img/polybots/Gears/tiger-04.png",
      legs: "assets/img/polybots/Gears/tiger-05.png",
    },
    stats: {
      tier: "B",
      manufacturer: "Yuto S. Corp.",
      chip: "Tiger",
      attack: { base: 175, upgraded: 200 },
      defence: { base: 140, upgraded: 165 },
      life: { base: 390, upgraded: 500 },
      agility: { base: 206, upgraded: 224 },
      energy: { base: 80, upgraded: 80 },
      total: { base: 991, upgraded: 1099 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 25, upgraded: 30 },
        defence: { base: 25, upgraded: 30 },
        life: { base: 50, upgraded: 60 },
        agility: { base: 36, upgraded: 40 },
        energy: { base: 15, upgraded: 15 },
      },
      {
        part: "chest",
        attack: { base: 30, upgraded: 35 },
        defence: { base: 35, upgraded: 40 },
        life: { base: 120, upgraded: 130 },
        agility: { base: 30, upgraded: 35 },
        energy: { base: 25, upgraded: 25 },
      },
      {
        part: "leftArm",
        attack: { base: 50, upgraded: 55 },
        defence: { base: 25, upgraded: 30 },
        life: { base: 60, upgraded: 70 },
        agility: { base: 45, upgraded: 45 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 50, upgraded: 55 },
        defence: { base: 25, upgraded: 30 },
        life: { base: 60, upgraded: 70 },
        agility: { base: 45, upgraded: 45 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 20, upgraded: 25 },
        defence: { base: 30, upgraded: 35 },
        life: { base: 100, upgraded: 110 },
        agility: { base: 40, upgraded: 45 },
        energy: { base: 20, upgraded: 20 },
      },
    ],
    special: {
      name: "Ronin's Fury",
      damage: "27 to 31 + Bonus Atributes",
      cost: 80,
      description:
        "With the ferocity of a Tiger, this chip delivers a powerful strike, embodying the raw strength and swift justice of a lone warrior.",
      icon: "",
    },
    skills: [
      {
        name: "Claw Slash",
        damage: "1 - 10",
        type: "Melee",
        energy: 2,
        effect: "None",
        icon: "/assets/img/polybots/icons/Garra Slash.png",
      },
      {
        name: "Cutting Defense",
        damage: "1 - 10",
        type: "Melee",
        energy: 2,
        effect: "Defense with counterattack",
        icon: "/assets/img/polybots/icons/Defesa de corte.png",
      },
    ],
    passive: {
      name: "Chest Passive",
      effect: "More Defence",
      type: "Melee",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "MTH-600": {
    name: "MTH-600",
    robotBanner: "assets/img/robots/purple.png",
    bigRobot: "assets/img/polybots/polybots/status/8.png",
    chip: "assets/img/polybots/Medals/Moth/medal_vesp gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/solana-01.png",
      chest: "assets/img/polybots/Gears/solana-02.png",
      leftArm: "assets/img/polybots/Gears/solana-03.png",
      rightArm: "assets/img/polybots/Gears/solana-04.png",
      legs: "assets/img/polybots/Gears/solana-05.png",
    },
    stats: {
      tier: "B",
      manufacturer: "Yuto S. Corp.",
      chip: "Moth",
      attack: { base: 120, upgraded: 145 },
      defence: { base: 260, upgraded: 280 },
      life: { base: 411, upgraded: 459 },
      agility: { base: 120, upgraded: 135 },
      energy: { base: 80, upgraded: 80 },
      total: { base: 991, upgraded: 1099 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 20, upgraded: 25 },
        defence: { base: 45, upgraded: 50 },
        life: { base: 70, upgraded: 80 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 15, upgraded: 15 },
      },
      {
        part: "chest",
        attack: { base: 20, upgraded: 25 },
        defence: { base: 60, upgraded: 65 },
        life: { base: 110, upgraded: 119 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 25, upgraded: 25 },
      },
      {
        part: "leftArm",
        attack: { base: 30, upgraded: 35 },
        defence: { base: 50, upgraded: 55 },
        life: { base: 70, upgraded: 80 },
        agility: { base: 25, upgraded: 25 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 30, upgraded: 35 },
        defence: { base: 50, upgraded: 55 },
        life: { base: 70, upgraded: 80 },
        agility: { base: 25, upgraded: 25 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 20, upgraded: 25 },
        defence: { base: 55, upgraded: 55 },
        life: { base: 91, upgraded: 100 },
        agility: { base: 30, upgraded: 35 },
        energy: { base: 20, upgraded: 20 },
      },
    ],
    special: {
      name: "Phantom Flight",
      damage: "21 to 29 + Bonus Atributes",
      cost: 80,
      description:
        "The Moth chip strikes swiftly and silently, much like its namesake, delivering a deadly blow with unmatched speed and precision.",
      icon: "",
    },
    skills: [
      {
        name: "Night Burst",
        damage: "5 - 15",
        type: "Dist",
        energy: 3,
        effect: "None",
        icon: "/assets/img/polybots/icons/Explosão noturna.png",
      },
      {
        name: "Stun Defense",
        damage: "5 - 12",
        type: "Melee",
        energy: 3,
        effect: "Opponent's chance of losing the next turn (20% chance)",
        icon: "/assets/img/polybots/icons/Defesa impressionante.png",
      },
    ],
    passive: {
      name: "Chest Passive",
      effect: "More Defence",
      type: "Melee",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "WLF-720": {
    name: "WLF-720",
    robotBanner: "assets/img/robots/wolf.png",
    bigRobot: "assets/img/polybots/polybots/status/10.png",
    chip: "assets/img/polybots/Medals/Wolf/medal_wolf gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/wolf-01.png",
      chest: "assets/img/polybots/Gears/wolf-02.png",
      leftArm: "assets/img/polybots/Gears/wolf-03.png",
      rightArm: "assets/img/polybots/Gears/wolf-04.png",
      legs: "assets/img/polybots/Gears/wolf-05.png",
    },
    stats: {
      tier: "B",
      manufacturer: "Yuto Synthetics Corporation",
      chip: "Wolf",
      attack: { base: 220, upgraded: 250 },
      defence: { base: 160, upgraded: 180 },
      life: { base: 261, upgraded: 289 },
      agility: { base: 270, upgraded: 300 },
      energy: { base: 80, upgraded: 80 },
      total: { base: 991, upgraded: 1099 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 40, upgraded: 40 },
        defence: { base: 35, upgraded: 45 },
        life: { base: 50, upgraded: 60 },
        agility: { base: 30, upgraded: 30 },
        energy: { base: 15, upgraded: 15 },
      },
      {
        part: "chest",
        attack: { base: 40, upgraded: 40 },
        defence: { base: 30, upgraded: 40 },
        life: { base: 80, upgraded: 90 },
        agility: { base: 30, upgraded: 30 },
        energy: { base: 25, upgraded: 25 },
      },
      {
        part: "leftArm",
        attack: { base: 50, upgraded: 60 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 30, upgraded: 30 },
        agility: { base: 55, upgraded: 65 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 50, upgraded: 60 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 30, upgraded: 30 },
        agility: { base: 55, upgraded: 65 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 40, upgraded: 50 },
        defence: { base: 35, upgraded: 35 },
        life: { base: 71, upgraded: 79 },
        agility: { base: 100, upgraded: 110 },
        energy: { base: 20, upgraded: 20 },
      },
    ],
    special: {
      name: "Lunar Fang Strike",
      damage: "24 to 33 + Bonus Atributes",
      cost: 80,
      description:
        "The Wolf chip allows for a powerful strike under the moonlight, delivering a ferocious attack.",
      icon: "",
    },
    skills: [
      {
        name: "Lacerating Claw",
        damage: "5 - 10",
        type: "Melee Stronge",
        energy: 2,
        effect: "None",
        icon: "/assets/img/polybots/icons/wolf 01.png",
      },
      {
        name: "Moonlit Slash",
        damage: "5 - 15",
        type: "Melee Stronge",
        energy: 3,
        effect: "Causes Bleed, dealing damage over multiple turns",
        icon: "/assets/img/polybots/icons/wolf 02.png",
      },
    ],
    passive: {
      name: "Simple Defense",
      effect: "Total Defense",
      type: "Defense",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "ICP-545": {
    name: "ICP-545",
    robotBanner: "assets/img/robots/yellow.png",
    bigRobot: "assets/img/polybots/polybots/status/9.png",
    chip: "assets/img/polybots/Medals/Wasp/medal_wasp_gold.png",
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/yellow-01.png",
      chest: "assets/img/polybots/Gears/yellow-02.png",
      leftArm: "assets/img/polybots/Gears/yellow-03.png",
      rightArm: "assets/img/polybots/Gears/yellow-04.png",
      legs: "assets/img/polybots/Gears/yellow-05.png",
    },
    stats: {
      tier: "C",
      manufacturer: "Yuto S. Corp.",
      chip: "Insect",
      attack: { base: 130, upgraded: 150 },
      defence: { base: 130, upgraded: 140 },
      life: { base: 450, upgraded: 500 },
      agility: { base: 130, upgraded: 140 },
      energy: { base: 60, upgraded: 60 },
      total: { base: 900, upgraded: 990 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 15, upgraded: 20 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 130, upgraded: 140 },
        agility: { base: 30, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "chest",
        attack: { base: 25, upgraded: 30 },
        defence: { base: 50, upgraded: 50 },
        life: { base: 130, upgraded: 140 },
        agility: { base: 20, upgraded: 20 },
        energy: { base: 20, upgraded: 20 },
      },
      {
        part: "leftArm",
        attack: { base: 35, upgraded: 40 },
        defence: { base: 10, upgraded: 15 },
        life: { base: 20, upgraded: 30 },
        agility: { base: 30, upgraded: 30 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 45, upgraded: 50 },
        defence: { base: 12, upgraded: 15 },
        life: { base: 20, upgraded: 30 },
        agility: { base: 20, upgraded: 20 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 10, upgraded: 10 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 150, upgraded: 160 },
        agility: { base: 30, upgraded: 40 },
        energy: { base: 10, upgraded: 10 },
      },
    ],
    special: {
      name: "Strong Shot",
      damage: "17-27 + Attributte bonus",
      cost: 60,
      description:
        "The Insect chip unleashes a precise and relentless attack, mimicking the persistent and focused nature of its namesake.",
      icon: "",
    },
    skills: [
      {
        name: "Fast Punch",
        damage: "1 - 5",
        type: "Melee",
        energy: 1,
        effect: "None",
        icon: "/assets/img/polybots/icons/Soco rápido.png",
      },
      {
        name: "Fast Shot",
        damage: "3 - 9",
        type: "Dist",
        energy: 2,
        effect: "None",
        icon: "/assets/img/polybots/icons/Tiro rápido.png",
      },
    ],
    passive: {
      name: "Simple Defense",
      effect: "Total Defense",
      type: "Melee",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png",
    },
  },

  "OCT-950": {
    name: "OCT-950",
    robotBanner: "assets/img/robots/oct.png", // Atualize o caminho para a imagem correta
    bigRobot: "assets/img/polybots/polybots/status/11.png", // Atualize o caminho para a imagem correta
    chip: "assets/img/polybots/Medals/Oct/medal_octopus_gold.png", // Atualize o caminho para a imagem correta
    box: "assets/img/Box.png",
    gears: {
      head: "assets/img/polybots/Gears/oct-01.png",
      chest: "assets/img/polybots/Gears/oct-02.png",
      leftArm: "assets/img/polybots/Gears/oct-03.png",
      rightArm: "assets/img/polybots/Gears/oct-04.png",
      legs: "assets/img/polybots/Gears/oct-05.png",
    },
    stats: {
      tier: "B",
      manufacturer: "Yuto Synthetics Corporation",
      chip: "Octopus",
      attack: { base: 141, upgraded: 151 },
      defence: { base: 260, upgraded: 290 },
      life: { base: 380, upgraded: 430 },
      agility: { base: 130, upgraded: 148 },
      energy: { base: 80, upgraded: 80 },
      total: { base: 991, upgraded: 1099 },
    },
    gearStats: [
      {
        part: "head",
        attack: { base: 30, upgraded: 30 },
        defence: { base: 35, upgraded: 35 },
        life: { base: 160, upgraded: 180 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 15, upgraded: 15 },
      },
      {
        part: "chest",
        attack: { base: 31, upgraded: 31 },
        defence: { base: 40, upgraded: 50 },
        life: { base: 80, upgraded: 90 },
        agility: { base: 20, upgraded: 25 },
        energy: { base: 25, upgraded: 25 },
      },
      {
        part: "leftArm",
        attack: { base: 25, upgraded: 30 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 30, upgraded: 35 },
        agility: { base: 25, upgraded: 25 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "rightArm",
        attack: { base: 25, upgraded: 30 },
        defence: { base: 30, upgraded: 30 },
        life: { base: 30, upgraded: 35 },
        agility: { base: 25, upgraded: 25 },
        energy: { base: 10, upgraded: 10 },
      },
      {
        part: "legs",
        attack: { base: 30, upgraded: 30 },
        defence: { base: 125, upgraded: 145 },
        life: { base: 80, upgraded: 90 },
        agility: { base: 40, upgraded: 48 },
        energy: { base: 20, upgraded: 20 },
      },
    ],
    special: {
      name: "Abyssal Tentacles",
      damage: "17 to 27 + Attribute bonus",
      cost: 80,
      description:
        "The Octopus chip summons powerful tentacles that bind and strike, mimicking the relentless force of the abyss.",
      icon: "",
    },
    skills: [
      {
        name: "Hydro Jet Blast",
        damage: "2 - 6",
        type: "Dist",
        energy: 3,
        effect: "None",
        icon: "/assets/img/polybots/icons/Hydro_Jet_Blast.png", 
      },
      {
        name: "Ink Cloud Blind",
        damage: "4 - 8",
        type: "Dist",
        energy: 3,
        effect: "Blind",
        icon: "/assets/img/polybots/icons/Ink_Cloud_Blind.png", 
      },
    ],
    passive: {
      name: "Simple Defense",
      effect: "Total Defense",
      type: "Defense",
      icon: "/assets/img/polybots/icons/Defesa Simpless.png", 
    },
  },

};
