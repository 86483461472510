import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { gearsMapping } from "../../pages/profile/Mapping/Mapping";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";

const Inventory = ({
  gears,
  openModal,
  openDepositModal,
  deposit = false,
  walletAddressNFT,
  onNFTDeposited,
  removeNFT,
}) => {
  const [NFTList, setNFTList] = useState([]);

  const filterGears = React.useCallback(
    gears ? gears.filter((gear) => !gear.withdrawn) : [],
    [gears]
  );

  const initMoralis = async () => {
    try {
      if (!Moralis.Core.isStarted) {
        await Moralis.start({
          apiKey: process.env.REACT_APP_PUBLIC_MORALIS_API_KEY,
        });
      }
    } catch (error) {
      console.error("Error initializing Moralis:", error);
    }
  };

  useEffect(() => {
    const fetchNFTs = async () => {
      if (walletAddressNFT) {
        try {
          await initMoralis();

          const address = walletAddressNFT;
          const chain = EvmChain.MOONBEAM;

          const response = await Moralis.EvmApi.nft.getWalletNFTs({
            address,
            chain,
          });

          const data = response.toJSON();

          console.log(data);

          const extractedParts = data.result.map((item) => {
            console.log(item);
            // Transforma metadata em JSON
            const metadata = JSON.parse(item.metadata);
            // Busca atributos de interesse
            const displayName = metadata.attributes.find(
              (attr) => attr.trait_type === "Display Name"
            )?.value;
            const stars = metadata.attributes.find(
              (attr) => attr.trait_type === "Stars"
            )?.value;
            const tokenId = JSON.parse(item.token_id);

            const attack = metadata.attributes.find(
              (attr) => attr.trait_type === "Attack"
            )?.value;

            const defense = metadata.attributes.find(
              (attr) => attr.trait_type === "Defense"
            )?.value;

            const health = metadata.attributes.find(
              (attr) => attr.trait_type === "Health"
            )?.value;

            const agility = metadata.attributes.find(
              (attr) => attr.trait_type === "Agility"
            )?.value;

            const energy = metadata.attributes.find(
              (attr) => attr.trait_type === "Energy"
            )?.value;

            return {
              displayName,
              stars,
              tokenId,
              attack,
              defense,
              health,
              agility,
              energy,
            };
          });
          setNFTList(extractedParts);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchNFTs();
  }, [walletAddressNFT]);

  useEffect(() => {
    setNFTList((prevList) =>
      prevList.filter((item) => item.tokenId !== removeNFT)
    );
  }, [removeNFT]);

  const itemsToDisplay = gears ? filterGears : NFTList;

  return (
    <>
      <Gears deposit={deposit}>
        {itemsToDisplay.length === 0 ? (
          <div
            style={{
              marginBlock: "auto",
              width: "100%",
              textAlign: "center",
              gridColumn: "1 / -1",
              color: "#fff",
              fontSize: "2rem",
              height: "auto",
            }}
          >
            Any part in inventory
          </div>
        ) : (
          itemsToDisplay.map((item, index) => {
            let robotPart = null;

            // Find the gear by matching displayName if using NFTList
            if (!gears) {
              const gearEntry = Object.entries(gearsMapping).find(
                ([_, gear]) => gear.name === item.displayName
              );
              if (gearEntry) {
                robotPart = gearEntry[1];
              }
            } else {
              // Original logic for gears
              robotPart =
                gearsMapping[item.id] ||
                gearsMapping[`${item.id}.${item.stars}`];
            }

            if (robotPart) {
              const backgroundImage =
                (gears ? item.stars : Number(item.stars)) === 1
                  ? "url('assets/img/polybots/Gears/bg-gray.png')"
                  : (gears ? item.stars : Number(item.stars)) === 2
                  ? "url('assets/img/polybots/Gears/bg-green.png')"
                  : "url('assets/img/polybots/Gears/bg-purple.png')";

              return (
                <div
                  key={index}
                  className="background"
                  style={{
                    marginBottom: "10px",
                    backgroundImage: backgroundImage,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <NFTImage
                    minted={item.minted}
                    src={robotPart.image}
                    onClick={
                      !gears
                        ? () =>
                            openDepositModal(
                              robotPart,
                              backgroundImage,
                              item,
                              walletAddressNFT
                            )
                        : () => openModal(robotPart, backgroundImage, item)
                    }
                  />
                  {gears && item.minted && (
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "12px",
                          width: "25px",
                          height: "25px",
                          backgroundImage: "url('assets/img/moonbeam.png')",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "100%",
                        }}
                      />
                      {!item.mintId && (
                        <span
                          style={{
                            color: "yellow",
                            fontSize: "1.5rem",
                            marginLeft: "5px",
                          }}
                        >
                          !
                        </span>
                      )}
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })
        )}
      </Gears>
    </>
  );
};

export const growAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const NFTImage = styled.img`
width: 100px;
height: 100px;
margin-left: ${({ minted }) => minted && "15px"};

@media (max-width: 770px) {
      width: 60px;
      height: 60px;
      margin-left: ${({ minted }) => minted && "10px"};
  }

  @media (max-width: 550px) {
      height: 100px;
      width: 100px;
      margin-left: ${({ minted }) => minted && "10px"};
  }

  @media (max-width: 450px) {
    img {
      width: 90px;
      height: 90px;
  }
`;

const Gears = styled.div`
  display: grid;
  grid-template-columns: ${({ deposit }) =>
    deposit ? "repeat(2, 1fr)" : "repeat(5, 1fr)"};
  gap: 15px;
  padding: ${({ deposit }) => (deposit ? "25px" : "15px")};
  align-items: flex-start;
  width: ${({ deposit }) => (deposit ? "90%" : "100%")};
  height: ${({ deposit }) =>
    deposit
      ? "600px"
      : "calc( 100% - 14% - 10px)"}; // 100% - 14% (back button size) - 10px (margin bottom back button)
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(1, 31, 47, 0.8);
  border-radius: 10px;
  border-left: 3px solid #00bfff;
  border-right: 3px solid #00bfff;
  border-top: 1px solid #00bfff;
  border-bottom: 1px solid #00bfff;
  animation: ${growAnimation} 0.1s ease-in-out;

  div {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: ${({ deposit }) =>
      deposit ? "repeat(2, 1fr)" : "repeat(4, 1fr)"};
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    height: ${({ deposit }) => deposit && "350px"};
  }

  @media (max-width: 770px) {
    div {
      width: 80px;
      height: 80px;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    padding: ${({ deposit }) => deposit && "5px"};

    div {
      width: 120px;
      height: 120px;
      margin-inline: auto;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 500px) {
    gap: 5px;
  }

  @media (max-width: 450px) {
    padding: ${({ deposit }) => (deposit ? "5px" : "10px")};
    width: ${({ deposit }) => deposit && "100%"};

    div {
      width: 110px;
      height: 110px;
      margin-inline: auto;
    }

    img {
      width: 90px;
      height: 90px;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #273444;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bfff;
    border-radius: 10px;
    border: 2px solid #273444;
  }

  scrollbar-width: thin;
  scrollbar-color: #3498db #273444;

  .background {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transition: 0.2s;
      transform: scale(1.05);
    }
  }
`;

export default Inventory;
