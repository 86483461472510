import styled, { keyframes } from "styled-components";

export const growAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const Popup = styled.div`
  background: URL("assets/img/bg/mint-bg.png") no-repeat center center;
  background-size: cover;
  width: 450px;
  height: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate(-50%, -50%);
  z-index: 900;
  border-radius: 10px;
  border: 3px solid rgba(0, 191, 255, 0.9);
  box-shadow: rgba(0, 191, 255, 0.6) 0px 2px 8px 0px;

  &.shortSize {
    height: 550px;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const CloseButton = styled.button`
  position: fixed;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 901;

  &:hover {
    color: #00bfff;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 1200px) {
    top: 15px;
    right: 15px;
  }

  @media (max-width: 1050px) {
    top: 15px;
    right: 15px;
  }

  @media (max-width: 450px) {
    top: 5px;
    right: 10px;
  }
`;

export const PopupContent = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${growAnimation} 0.1s ease-in-out;
  overflow-y: hidden;

  @media (max-width: 450px) {
    padding: 5px;
  }
`;

export const AttributesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-itens: center;
  gap: 15px;
  flex-direction: column;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  background-color: #032054;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .blue {
    color: #00bfff;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 899;
  cursor: pointer;
`;

const spin = keyframes`
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
`;

export const CardBackground = styled.div`
  content: "";
  width: 104%;
  height: 110%;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  position: absolute;
  z-index: -1;
  top: -4%;
  left: -2%;
  animation: ${spin} 2.5s linear infinite;

  &:hover {
    animation-duration: 1s;
  }

  @property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
  }
`;

export const CardContainer = styled.div`
  margin-top: 5px;
  background: #121212;
  width: 150px;
  height: 50px;
  padding: 3px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.2em;
  color: rgb(88 199 250 / 100%);
  cursor: pointer;
  user-select: none;

  &:hover {
    ${CardBackground} {
      animation-duration: 0.4s;
    }
  }
`;

export const CardGlow = styled.div`
  position: absolute;
  content: "";
  top: -5%; /* Negative percentage to extend above the card */
  left: -10%; /* Negative percentage to extend left of the card */
  right: -10%; /* Negative percentage to extend right of the card */
  bottom: -5%; /* Negative percentage to extend below the card */
  z-index: -1;
  height: 120%;
  width: 120%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc((50px * 1.1) / 5));
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  opacity: 1;
  transition: opacity 0.5s;
  animation: ${spin} 2.5s linear infinite;
`;

export const CardContent = styled.div`
  z-index: 1;
`;

export const CardWrapper = styled.div`
  position: relative;
`;
