import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function CtaArea() {
  const handleDiscordClick = () => {
    window.open("https://discord.gg/PCFAg5E6ED", "_blank");
  };

  const partners_list = [
    {
      src: "assets/img/partners/levelup.png",
      title: "",
      url: "https://br.leveluplatam.com/",
    },
    { src: "assets/img/partners/labbits.png", title: "", url: "" },
    {
      src: "assets/img/partners/ibeed.png",
      title: "",
      url: "https://ibeed.xyz/",
    },
    {
      src: "assets/img/partners/gotabg.png",
      title: "",
      url: "https://gotas.social/",
    },
    {
      src: "assets/img/partners/picce.png",
      title: "",
      url: "https://picce.xyz/",
    },
  ];

  const PartnersContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    flex-direction: column;
    width: 100%;

    @media (max-width: 768px) {
      margin-top: 60px;
    }
  `;

  const Title = styled.h2`
    color: white;
    font-size: 2.5em;

    @media (max-width: 768px) {
      font-size: 2em;
    }

    @media (max-width: 480px) {
      font-size: 1.5em;
    }
  `;

  const Divider = styled.div`
    width: 150px;
    height: 2px;
    background-color: white;
    margin: 0 0 20px 0;

    @media (max-width: 480px) {
      width: 100px;
    }
  `;

  const PartnersGrid = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15%;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0;

    @media (max-width: 768px) {
      gap: 10%;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 20px; /* Define o gap em pixels para garantir o espaçamento entre os itens */
    }
  `;

  const PartnerLink = styled.a`
    max-width: 200px;
    width: 100%;
  `;

  const HoverImage = styled.img`
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 80%;
    cursor: pointer;
    max-width: 100px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }

    @media (max-width: 768px) {
      max-width: 80px;
    }

    @media (max-width: 480px) {
      max-width: 60px;
    }
  `;

  return (
    <section className="cta-area cta-bg">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-5 col-lg-6">
            <img
              src="assets/img/images/cta_img2.png"
              alt=""
              style={{ width: "100%", marginBottom: "15px" }}
            />
          </div>
          <div className="col-lg-6">
            <div className="cta-content">
              <div className="section-title title-style-three white-title mb-50">
                <h2>
                  JOIN OUR{" "}
                  <span style={{ color: "#40a5f8" }}>POLYBOTS COMMUNITY</span>
                </h2>
                <p>
                  By Kuma Games <br /> Become part of our amazing community, get
                  exclusive updates, and connect with other trainers as they
                  navigate their journeys in the exciting world of Polybots
                  Rumble.
                </p>
              </div>
              <div className="cta-btn">
                <div className="btn btn-style-two" onClick={handleDiscordClick}>
                  JOIN OUR DISCORD
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PartnersContainer>
        <Title>PARTNERS</Title>
        <Divider />
        <PartnersGrid>
          {partners_list.map((item, index) => (
            <PartnerLink
              key={index}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <HoverImage
                src={item.src}
                alt={item.title}
                className="hover-effect"
              />
            </PartnerLink>
          ))}
        </PartnersGrid>
      </PartnersContainer>
    </section>
  );
}

export default CtaArea;
