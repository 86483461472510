import React from "react";
import styled from "styled-components";

const flagMapping = {
  argentina: "assets/img/polybots/Flags/argentina.png",
  australia: "assets/img/polybots/Flags/australia.png",
  brazil: "assets/img/polybots/Flags/brazil.png",
  croatia: "assets/img/polybots/Flags/croatia.png",
  "?????": "assets/img/polybots/Flags/defeault.png",
  england: "assets/img/polybots/Flags/england.png",
  austria: "assets/img/polybots/Flags/austria.png",
  finland: "assets/img/polybots/Flags/finland.png",
  france: "assets/img/polybots/Flags/france.png",
  germany: "assets/img/polybots/Flags/germany.png",
  iceland: "assets/img/polybots/Flags/iceland.png",
  italy: "assets/img/polybots/Flags/italy.png",
  jamaica: "assets/img/polybots/Flags/jamaica.png",
  japan: "assets/img/polybots/Flags/japan.png",
  poland: "assets/img/polybots/Flags/poland.png",
  russia: "assets/img/polybots/Flags/russia.png",
  "south-africa": "assets/img/polybots/Flags/south-africa.png",
  switzerland: "assets/img/polybots/Flags/switzerland.png",
  tunisia: "assets/img/polybots/Flags/tunisia.png",
  "united-states": "assets/img/polybots/Flags/united-states.png",
  uruguay: "assets/img/polybots/Flags/uruguay.png",
};

const getFlag = (nationality) => {
  const countryName = nationality.toLowerCase().replace(/\s/g, "-");
  return flagMapping[countryName] || flagMapping["default"];
};

const handleClick = () => {
  window.open("/polybots", "_blank");
};

const Trainers = ({ selectedTrainer }) => {
  const trainers = [
    {
      name: "Andy",
      image: "/assets/img/wiki/trainers/andy.png",
      nationality: "United States",
      robot: "FNX-210",
      imgRobot: "/assets/img/wiki/polybots/3.png",
    },
    {
      name: "Tai",
      image: "/assets/img/wiki/trainers/tai.png",
      nationality: "Japan",
      robot: "TMN-380",
      imgRobot: "/assets/img/wiki/polybots/7.png",
    },
    {
      name: "Max",
      image: "/assets/img/wiki/trainers/max.png",
      nationality: "Australia",
      robot: "NTX-480",
      imgRobot: "/assets/img/wiki/polybots/4.png",
    },
    {
      name: "Aria",
      image: "/assets/img/wiki/trainers/aria.png",
      nationality: "South Africa",
      robot: "ANK-590",
      imgRobot: "/assets/img/wiki/polybots/5.png",
    },
    {
      name: "Sami",
      image: "/assets/img/wiki/trainers/sami.png",
      nationality: "Poland",
      robot: "MTH-600",
      imgRobot: "/assets/img/wiki/polybots/8.png",
    },
    {
      name: "Dr. Yuto",
      image: "/assets/img/wiki/trainers/dr.png",
      nationality: "Japan",
      robot: "???",
      imgRobot: "/assets/img/wiki/polybots/1.png",
    },
    {
      name: "Dr. Yumi",
      image: "/assets/img/wiki/trainers/dra.png",
      nationality: "Japan",
      robot: "???",
      imgRobot: "/assets/img/wiki/polybots/1.png",
    },
    {
      name: "Yuri",
      image: "/assets/img/wiki/trainers/yuri.png",
      nationality: "Finland",
      robot: "WLF-720",
      imgRobot: "/assets/img/polybots/polybots/11.png",
    },
    {
      name: "Nicola",
      image: "/assets/img/wiki/trainers/nicola.png",
      nationality: "Austria",
      robot: "OCT-950",
      imgRobot: "/assets/img/polybots/polybots/icp.png",
    },
  ];

  const displayedTrainers =
    selectedTrainer !== null ? [trainers[selectedTrainer]] : trainers;

  return (
    <Container>
      <Title>
        {selectedTrainer !== null ? "Trainer Details" : "Polybots Trainers"}
      </Title>
      <Banner src={"assets/img/wiki/Banner X twiter kuma.png"} alt="Trainers Banner" />
      {displayedTrainers.map((trainer, index) => (
        <TrainerContainer key={index}>
          <MarginDiv>
            <TrainerName>{trainer.name}</TrainerName>
            <TrainerImage src={trainer.image} alt={trainer.name} />
          </MarginDiv>
          <TrainerDetails>
            <TrainerInfo>
              <HighlightedText>Nationality:</HighlightedText>{" "}
              {trainer.nationality}
              <FlagImage
                src={getFlag(trainer.nationality)}
                alt={trainer.nationality}
              />
            </TrainerInfo>
            <TrainerInfo>
              <HighlightedText>Main Polybot:</HighlightedText> {trainer.robot}
            </TrainerInfo>
          </TrainerDetails>
          <ImageDiv>
            <HoverImage src={trainer.imgRobot} onClick={handleClick} />
          </ImageDiv>
        </TrainerContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;

  @media (max-width: 400px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Banner = styled.img`
  max-width: 100%; // Ajuste conforme necessário
  margin-bottom: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;



const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;

  @media (max-width: 515px) {
    font-size: 2.5em;
  }

  @media (max-width: 420px) {
    font-size: 2em;
  }

  @media (max-width: 330px) {
    font-size: 1.8em;
  }
`;

const TrainerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;

  @media (max-width: 990px) {
    gap: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
    width: 100%;
  }
`;

const MarginDiv = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const TrainerImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 10px;
`;

const TrainerDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    text-align: center;
    margin-top: 10px;
  }
`;

const TrainerName = styled.h2`
  font-size: 1.5em;
  margin: 10px 0;
  // text-align: left;
  // width: 46%;

  // @media (max-width: 990px) {
  //   width: 65%;
  //   text-align: left;
  // }

  // @media (max-width: 770px) {
  //   width: 52%;
  //   text-align: left;
  // }

  // @media (max-width: 600px) {
  //   // width: 50%;
  //   text-align: center;
  // }

  //   @media (max-width: 325px) {
  //     width: 100%;
  //     text-align: left;
  //   }
`;

const TrainerInfo = styled.p`
  font-size: 1em;
  margin: 3px 0;

  @media (max-width: 400px) {
    font-size: 0.9em;
    margin: 0px 0;
  }
`;

const HighlightedText = styled.span`
  font-size: 1.1em;
  font-weight: bold;
  color: #40a5f8;
`;

const FlagImage = styled.img`
  width: 30px;
  height: 20px;
  margin-left: 8px;
`;

const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -60px;
  margin-top: 20px;

  @media (max-width: 990px) {
    margin-left: 0;
    margin-top: 50px;
    align-items: center;
  }

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const HoverImage = styled.img`
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 100%;
  cursor: pointer;
  max-width: 150px;
  width: 100%;
  margin-top: 70px;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  @media (max-width: 990px) {
    margin-top: 0;
  }
`;

export default Trainers;
